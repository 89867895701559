import { Rating } from "react-simple-star-rating";
import product1 from "../../images/products/product9.png";
import thumb from "../../images/products/thumbs/thumb4.png";
import thumb1 from "../../images/products/thumbs/thumb5.png";
import thumb2 from "../../images/products/thumbs/thumb6.png";
import { AiOutlinePlusCircle } from "react-icons/ai";

import addcart from "../../images/icons/addcart.png";
import rightRedArrow from "../../images/icons/rightRedArrow.png";

import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";

import product from "../../images/products/product.png";
import product2 from "../../images/products/product2.png";
import product3 from "../../images/products/product3.png";
import product4 from "../../images/products/product4.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import axios from "axios";
import Util from "../../Classes/Util";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const DetailedProduct = (props) => {
  const [product, setProduct] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variant, setVariant] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [selectedAttributeValues, setSelectedAttributeValues] = useState([]);

  const [isLoadingDetailed, setIsLoadingDetailed] = useState(false);
  const [details, setDetails] = useState({
    ProductId: 0,
    ProductName: "",
    SmallDescription: "",
  });
  const [plainText, setPlainText] = useState("");
  const [att, setAtt] = useState([]);
  const [variations, setVariations] = useState([]);
  const [imgArray, setImgArray] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [crossSell, setCrossSell] = useState([]);
  const [upSell, setUpSell] = useState([]);
  const [isLogged, setIsLogged] = useState(false);
  const [WishListItems, setWishListItems] = useState([]);
  const [textAtttibute, setTextAttribute] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  var text = "";

  const GetProduct = async () => {
    try {
      fetch(
        Global.API_URL +
        `products/GetProductWithVariations/${params.ProductId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
        }
      )
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
        })
        .then((response) => {
          setProduct(response);
          setVariants(response.ProductVariations);
          var defaultVariant = response.ProductVariations.find(a => a.IsDefaultVariation);

          if (defaultVariant != null) {
            setVariant(defaultVariant);
          } else {
            setVariant(response.ProductVariations[0]);
          }
        });
    } catch (error) {
      if (error.response && error.response.data) {
      } else {
      }
    }
  };

  useEffect(() => {
    if (product) {

      const tempAttributes = [];

      product.ProductVariations.forEach((variant) => {
        variant.AssignAttributeValueToProductVariations.forEach(
          (variantAttr) => {
            const existingAttributeIndex = tempAttributes.findIndex(
              (attr) =>
                attr.attributeId === variantAttr.AttributeValue.AttributeId
            );

            if (existingAttributeIndex === -1) {
              // Attribute not found, add it to tempAttributes
              tempAttributes.push({
                attributeId: variantAttr.AttributeValue.AttributeId,
                attributeName: variantAttr.AttributeValue.Attribute.Attribute1,
                attributeValues: [
                  {
                    attributeValueId:
                      variantAttr.AttributeValue.AttributeValueId,
                    attributeValue:
                      variantAttr.AttributeValue.AttributeValueName,
                    colorValue: variantAttr.AttributeValue.Attribute.Attribute1.toLowerCase() === "color" ? variantAttr.AttributeValue.AttributesValue : null

                  },
                ],
              });
            } else {
              // Attribute found, check if the attribute value already exists
              const existingValueIndex = tempAttributes[
                existingAttributeIndex
              ].attributeValues.findIndex(
                (value) =>
                  value.attributeValueId ===
                  variantAttr.AttributeValue.AttributeValueId
              );

              if (existingValueIndex === -1) {
                // Attribute value not found, add it to the existing attribute
                tempAttributes[existingAttributeIndex].attributeValues.push({
                  attributeValueId: variantAttr.AttributeValue.AttributeValueId,
                  attributeValue: variantAttr.AttributeValue.AttributeValueName,
                  colorValue: variantAttr.AttributeValue.Attribute.Attribute1.toLowerCase() === "color" ? variantAttr.AttributeValue.AttributesValue : null
                });
              }
            }
          }
        );
      });
      variant.AssignAttributeValueToProductVariations.forEach(item => {
        handleAttributeValueClick(item.AttributeValue.AttributeId, item.AttributeValue.AttributeValueId)
      });
      setAttributes(tempAttributes);
      setFilteredAttributes(tempAttributes);
    }
  }, [product]);

  useEffect(() => {
    // Filter variants based on the selected attribute values
    if (product && product.ProductVariations) {
      const filteredVariants = product.ProductVariations.filter((variant) =>
        selectedAttributeValues.every(({ attributeId, attributeValueId }) =>
          variant.AssignAttributeValueToProductVariations.some(
            (variantAttr) =>
              variantAttr.AttributeValue.AttributeValueId ===
              attributeValueId &&
              variantAttr.AttributeValue.AttributeId === attributeId
          )
        )
      );

      setVariants(filteredVariants);
      var defaultVariant = filteredVariants.find(a => a.IsDefaultVariation);
      if (defaultVariant != null) {
        setVariant(defaultVariant);
      } else {
        setVariant(filteredVariants[0]);
      }
    }
  }, [selectedAttributeValues]);

  const handleAttributeValueClick = (attributeId, attributeValueId) => {
    // Check if the clicked attribute and value pair already exists in selectedAttributeValues
    const existingIndex = selectedAttributeValues.findIndex(
      (value) => value.attributeId === attributeId
    );

    if (existingIndex !== -1) {
      // If the attribute exists, find its index in selectedAttributeValues
      const attributeValueIndex = selectedAttributeValues.findIndex(
        (value) =>
          value.attributeId === attributeId &&
          value.attributeValueId === attributeValueId
      );
      if (attributeValueIndex !== -1) {
        // If the exact pair exists, remove it from the array
        setSelectedAttributeValues((prevValues) =>
          prevValues.filter((_, index) => index !== attributeValueIndex)
        );
      } else {
        // If the attribute exists but with a different value, update its value
        const updatedSelectedAttributeValues = [...selectedAttributeValues];


        updatedSelectedAttributeValues[existingIndex].attributeValueId =
          attributeValueId;

        setSelectedAttributeValues(updatedSelectedAttributeValues);
      }
    } else {
      // If the attribute doesn't exist, add it along with the attribute value
      setSelectedAttributeValues((prevValues) => [
        ...prevValues,
        { attributeId, attributeValueId },
      ]);
    }


  };

  const getProductDetails = async () => {
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const offset = new Date().getTimezoneOffset();
    const URL =
      Global.API_URL +
      "Products/GetProductVariationByVariationId/" +
      params.ProductVariationId +
      "/" +
      offset;
    fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        setDetails(result);
        // document.getElementById("test").src = Global.PHOTO_URL + result.Image0;
        // if (result.Image0 != "") {
        //   document.getElementById("image0").src =
        //     Global.PHOTO_URL + result.Image0;
        //   document.getElementById("image01").src =
        //     Global.PHOTO_URL + result.Image0;
        // }
        // if (result.Image1 != "") {
        //   document.getElementById("image1").src =
        //     Global.PHOTO_URL + result.Image1;
        //   document.getElementById("image11").src =
        //     Global.PHOTO_URL + result.Image1;
        // }
        // if (result.Image2 != "") {
        //   document.getElementById("image2").src =
        //     Global.PHOTO_URL + result.Image2;
        //   document.getElementById("image21").src =
        //     Global.PHOTO_URL + result.Image2;
        // }
        // if (result.Image3 != "") {
        //   document.getElementById("image3").src =
        //     Global.PHOTO_URL + result.Image3;
        //   document.getElementById("image31").src =
        //     Global.PHOTO_URL + result.Image3;
        // }
        // if (result.Image4 != "") {
        //   document.getElementById("image4").src =
        //     Global.PHOTO_URL + result.Image4;
        //   document.getElementById("image41").src =
        //     Global.PHOTO_URL + result.Image4;
        // }
        var arr = [...imgArray];

        if (result.Image0 !== "") {
          arr.push(result.Image0);
          variations.push({
            index: 0,
            image: result.Image0,
          });
          setImgArray(arr);
        }
        if (result.Image1 !== "") {
          arr.push(result.Image1);
          variations.push({
            index: 1,
            image: result.Image1,
          });
          setImgArray(arr);
        }
        if (result.Image2 !== "") {
          arr.push(result.Image2);
          variations.push({
            index: 2,
            image: result.Image2,
          });
          setImgArray(arr);
        }
        if (result.Image3 !== "") {
          arr.push(result.Image3);
          variations.push({
            index: 3,
            image: result.Image3,
          });
          setImgArray(arr);
        }
        if (result.Image4 !== "") {
          arr.push(result.Image4);
          variations.push({
            index: 4,
            image: result.Image4,
          });
          setImgArray(arr);
        }

        const text = result.Description;
        const convertText = text
          .replaceAll("[Paragraph]", "<p>")
          .replaceAll("[EndParagraph]", "</p>")
          .replaceAll("[NewLine]", "<br/>");

        setPlainText(convertText);
      });
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId

    //   })
  };

  // const getProductDetails = async () => {
  //   var groupId;
  //   if (localStorage.getItem("WishList")) {
  //     groupId = 4
  //   } else {
  //     groupId = 3
  //   }
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   var country;
  //   fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((Response) => {
  //       if (Response.ok) {
  //         return Response.json();
  //       } else {
  //         return ""
  //       }
  //     })
  //     .then((Result) => {
  //       country = Result.CountryId
  //       const offset = new Date().getTimezoneOffset();
  //       const URL = Global.API_URL + "Products/GetProductByID/" + params.ProductId + "/" + offset + "/" + country + "/" + groupId;
  //       fetch(URL, {
  //         method: "Get",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": Global.API_URL,
  //         },
  //       })
  //         .then((resp) => {
  //           if (resp.ok) {
  //             return resp.json();
  //           }
  //         })
  //         .then((result) => {
  //           setDetails(result.Product);
  //           setVariations(result.ProductVariation)
  //           var arr = [...imgArray];
  //           if (result.ProductVariation[0].Image0 !== "") {
  //             arr.push(result.ProductVariation[0].Image0)
  //             setImgArray(arr)
  //           }
  //           if (result.ProductVariation[0].Image1 !== "") {
  //             arr.push(result.ProductVariation[0].Image1)
  //             setImgArray(arr)
  //           }
  //           if (result.ProductVariation[0].Image2 !== "") {
  //             arr.push(result.ProductVariation[0].Image2)
  //             setImgArray(arr)
  //           }
  //           if (result.ProductVariation[0].Image3 !== "") {
  //             arr.push(result.ProductVariation[0].Image3)
  //             setImgArray(arr)
  //           }
  //           if (result.ProductVariation[0].Image4 !== "") {
  //             arr.push(result.ProductVariation[0].Image4)
  //             setImgArray(arr)
  //           }
  //         });
  //     })
  // }
  // const getAssignedAtt = async () => {
  //   const offset = new Date().getTimezoneOffset();
  //   const URL = Global.API_URL + "Products/GetAssignedAttForProductVariation/" + params.ProductVariationId + "/" + offset;
  //   fetch(URL, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": Global.API_URL,
  //     },
  //   })
  //     .then((resp) => {
  //       if (resp.ok) {
  //         return resp.json();
  //       }
  //     })
  //     .then((result) => {
  //       // setAssignedAtt(result);
  //       var arr1 = [...att];
  //       if (result.length > 0) {
  //         result.map((att) => {
  //           arr1.push(att);

  //           setAtt(arr1);

  //         })

  //       }
  //     });
  // }
  const getAssignedAtt = async () => {
    const token = Cookies.get("CustomerToken");
    const offset = new Date().getTimezoneOffset();
    const URL =
      Global.API_URL +
      "Attributes/GetDifferentsProductAttributesValues/" +
      params.ProductId;
    fetch(URL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        // setAssignedAtt(result);
        // var arr1 = [...att];
        // if (result.length > 0) {
        //   result.map((att) => {
        //     arr1.push(att);

        //     setAtt(arr1);

        //   })

        // }

        setAtt(result);

        // const arrayHashmap = att.reduce((obj, item) => {
        //   obj[item.ProductVarationId] ? obj[item.ProductVarationId].AttributeValueName += " " + item.AttributeValueName : (obj[item.ProductVarationId] = { ...item });
        //   return obj;
        // }, {});
        // const mergedArray = Object.values(arrayHashmap);
        // setTextAttribute(arrayHashmap);
      });
  };

  const getCrossSell = async () => {
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const offset = new Date().getTimezoneOffset();
    const URL =
      Global.API_URL +
      "Products/GetCrossSells/" +
      params.ProductId +
      "/" +
      offset +
      "/" +
      125 +
      "/" +
      groupId;
    fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        setCrossSell(result);
      });
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId

    //   })
  };
  // const getUpSell = async () => {
  //   var groupId;
  //   if (localStorage?.getItem("WishList")) {
  //     groupId = 4
  //   } else {
  //     groupId = 3
  //   }
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   var country;
  //   fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((Response) => {
  //       if (Response.ok) {
  //         return Response.json();
  //       } else {
  //         return ""
  //       }
  //     })
  //     .then((Result) => {
  //       country = Result.CountryId
  //       const offset = new Date().getTimezoneOffset();
  //       const URL = Global.API_URL + "Products/GetUpSells/" + params.ProductId + "/" + offset + "/" + country + "/" + groupId;
  //       fetch(URL, {
  //         method: "Get",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": Global.API_URL,
  //         },
  //       })
  //         .then((resp) => {
  //           if (resp.ok) {
  //             return resp.json();
  //           }
  //         })
  //         .then((result) => {
  //           setUpSell(result)
  //         });
  //     })
  //   setIsLoading(false)
  // }
  const HandleSubmit = async (ProductId, ProductVariationId) => {
    if (variant != null) {
      if (isLogged) {
        let allAttributesSelected = true;
        attributes.forEach((attribute) => {
          const exisitingInSelectedAttribute = selectedAttributeValues.find(
            (u) => u.attributeId == attribute.attributeId
          );
          if (exisitingInSelectedAttribute == null) {
            toast.error(`Please select ${attribute.attributeName}`);
            allAttributesSelected = false;
          }
        });
        if (!allAttributesSelected) {
          return;
        }
        const getItem = Cookies.get("Cart") !== undefined;
        if (getItem) {
          // var CartId = Util.decrypt(localStorage?.getItem("Cart"));
          var CartId = Cookies.get("Cart");
          const token = Cookies.get("CustomerToken");
          let Response = await fetch(Global.API_URL + "Cart/AddItemToCart", {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.API_URL,
            },

            body: JSON.stringify({
              CartId: CartId,
              ProductId: variant.ProductId,
              VariationId: variant.ProductVariationId,
              Quantity: 1,
            }),
          })
            .then((response) => {
              if (response.ok) {
                toast.success("Item added to cart");
                return response.json();
              } else {
                return Response.text().then((text) => {
                  throw new Error(text);
                });
              }
            })
            .catch((error) => {
              toast.error("Could Not Add");
            });
        }
      } else {
        navigate("/UserAuthentication");
      }
    }
  };
  const HandleAddToWishList = async (ProductId, ProductVariationId) => {
    if (isLogged) {
      const getItem = Cookies.get("WishList") !== undefined;
      if (getItem) {
        // var WishlistId = Util.decrypt(localStorage?.getItem("WishList"));
        var WishlistId = Cookies.get("WishList");
        const token = Cookies.get("CustomerToken");
        let Response = await fetch(
          Global.API_URL + "Wishlist/AddItemToWishlist",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.API_URL,
            },

            body: JSON.stringify({
              WishlistId: WishlistId,
              ProductId: ProductId,
              VariationId: ProductVariationId,
            }),
          }
        )
          .then((response) => {
            if (response.ok) {
              toast.success("Item added to wishList");
              return response.json();
            } else {
              return Response.text().then((text) => {
                throw new Error(text);
              });
            }
          })
          .catch((error) => {
            toast.error("Could Not Add");
          });
      }
    } else {
      navigate("/UserAuthentication");
    }
  };

  const HandleRemoveFromWishList = async (ProductId) => {
    if (isLogged) {
      const getItem = Cookies("WishList") !== undefined;
      if (getItem) {
        // var WishlistId = Util.decrypt(localStorage?.getItem("WishList"));
        var WishlistId = Cookies.get("WishList");
        const token = Cookies.get("CustomerToken");
        let Response = await fetch(
          Global.API_URL +
          "Wishlist/RemoveItemFromWishlist" +
          "/" +
          ProductId +
          "/" +
          WishlistId,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.API_URL,
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              toast.success("Item Removed from wishList");
              return response.json();
            } else {
              return Response.text().then((text) => {
                throw new Error(text);
              });
            }
          })
          .catch((error) => {
            toast.error("Could Not Remove");
          });
      }
    } else {
      navigate("/UserAuthentication");
    }
  };

  const getWishListItems = async () => {
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const offset = new Date().getTimezoneOffset();
    const getItem = Cookies.get("WishList") !== undefined;
    if (getItem) {
      // var WishlistId = Util.decrypt(localStorage?.getItem("WishList"))
      var WishlistId = Cookies.get("WishList");
      const token = Cookies.get("CustomerToken");
      fetch(
        Global.API_URL +
        "Wishlist/GetWishistItems/" +
        WishlistId +
        "/" +
        offset +
        "/" +
        125 +
        "/" +
        groupId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((Result) => {
          setWishListItems(Result.WishlistItems);
        });
    }
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId

    //   })
  };

  function splitFirstOccurrence(str, separator) {
    const [first, ...rest] = str.split(separator);

    const remainder = rest;

    return { first, remainder };
  }

  useEffect(() => {
    setIsLoadingDetailed(true);
    GetProduct();
    if (Cookies.get("CustomerToken") === undefined) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
    getProductDetails();
    props.setHeaderTitle(params.ProductName);
    props.setFooterActive(2);
    window.scrollTo(0, 0);
    getAssignedAtt();

    getCrossSell();
    // getUpSell();
    getWishListItems();

    setTimeout(() => {
      setIsLoadingDetailed(false);
    }, 2500);
  }, [params.ProductName, params.ProductVariationId]);
  return (
    <>
      <div className="container mt-60 detailedProduct">
        <div className="row">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Detailed Product</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-5">
            {
              variant && (
                <>
                  <Swiper
                    spaceBetween={10}
                    navigation={false}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                    }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                    allowTouchMove={false}
                  >
                    <>
                      {variant.Images[0] && (
                        <SwiperSlide>
                          <img id="image0" src={variant.Images[0] && Global.PHOTO_URL + variant.Images[0]} alt="0" />
                          <div>
                            {((variant != null && !variant.IsInStock) || variant === null) && (
                              <div className="out-of-stock-overlay">Out of Stock</div>
                            )}
                          </div>
                        </SwiperSlide>
                      )}
                      {variant.Images[1] && (
                        <SwiperSlide>
                          <img id="image1" src={variant.Images[1] && Global.PHOTO_URL + variant.Images[1]} alt="1" />
                          <div>
                            {((variant != null && !variant.IsInStock) || variant === null) && (
                              <div className="out-of-stock-overlay">Out of Stock</div>
                            )}
                          </div>
                        </SwiperSlide>
                      )}
                      {variant.Images[2] && (
                        <SwiperSlide>
                          <img id="image2" src={variant.Images[2] && Global.PHOTO_URL + variant.Images[2]} alt="2" />
                          <div>
                            {((variant != null && !variant.IsInStock) || variant === null) && (
                              <div className="out-of-stock-overlay">Out of Stock</div>
                            )}
                          </div>
                        </SwiperSlide>
                      )}
                      {variant.Images[3] && (
                        <SwiperSlide>
                          <img id="image3" src={variant.Images[3] && Global.PHOTO_URL + variant.Images[3]} alt="3" />
                          <div>
                            {((variant != null && !variant.IsInStock) || variant === null) && (
                              <div className="out-of-stock-overlay">Out of Stock</div>
                            )}
                          </div>
                        </SwiperSlide>
                      )}
                      {variant.Images[4] && (
                        <SwiperSlide>
                          <img id="image4" src={variant.Images[4] && Global.PHOTO_URL + variant.Images[4]} alt="4" />
                          <div>
                            {((variant != null && !variant.IsInStock) || variant === null) && (
                              <div className="out-of-stock-overlay">Out of Stock</div>
                            )}
                          </div>
                        </SwiperSlide>
                      )}
                      {/* {details.Image0 != "" && (
                  <SwiperSlide>
                    <img id="image0" src="" alt={details.ProductName} />
                    <div>
                      {!details.InStock && (
                        <div className="out-of-stock-overlay">Out of Stock</div>
                      )}
                    </div>
                  </SwiperSlide>
                )}
                {details.Image1 != "" && (
                  <SwiperSlide>
                    <img id="image1" src="" alt={details.ProductName} />
                    <div>
                      {!details.InStock && (
                        <div className="out-of-stock-overlay">Out of Stock</div>
                      )}
                    </div>
                  </SwiperSlide>
                )}
                {details.Image2 != "" && (
                  <SwiperSlide>
                    <img id="image2" src="" alt={details.ProductName} />
                    <div>
                      {!details.InStock && (
                        <div className="out-of-stock-overlay">Out of Stock</div>
                      )}
                    </div>
                  </SwiperSlide>
                )}
                {details.Image3 != "" && (
                  <SwiperSlide>
                    <img id="image3" src="" alt={details.ProductName} />
                    <div>
                      {!details.InStock && (
                        <div className="out-of-stock-overlay">Out of Stock</div>
                      )}
                    </div>
                  </SwiperSlide>
                )}
                {details.Image4 != "" && (
                  <SwiperSlide>
                    <img id="image4" src="" alt={details.ProductName} />
                    <div>
                      {!details.InStock && (
                        <div className="out-of-stock-overlay">Out of Stock</div>
                      )}
                    </div>
                  </SwiperSlide>
                )} */}
                    </>
                  </Swiper>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper mt-3"
                  >
                    {variant.Images[0] && (
                      <SwiperSlide>
                        <img id="image01" src={variant.Images[0] && Global.PHOTO_URL + variant.Images[0]} alt="0" />
                      </SwiperSlide>
                    )}
                    {variant.Images[1] && (
                      <SwiperSlide>
                        <img id="image11" src={variant.Images[1] && Global.PHOTO_URL + variant.Images[1]} alt="1" />
                      </SwiperSlide>
                    )}
                    {variant.Images[2] && (
                      <SwiperSlide>
                        <img id="image21" src={variant.Images[2] && Global.PHOTO_URL + variant.Images[2]} alt="2" />
                      </SwiperSlide>
                    )}
                    {variant.Images[3] && (
                      <SwiperSlide>
                        <img id="image31" src={variant.Images[3] && Global.PHOTO_URL + variant.Images[3]} alt="3" />
                      </SwiperSlide>
                    )}
                    {variant.Images[4] && (
                      <SwiperSlide>
                        <img id="image41" src={variant.Images[4] && Global.PHOTO_URL + variant.Images[4]} alt="4" />
                      </SwiperSlide>
                    )}
                  </Swiper>
                </>
              )
            }
          </div>

          <div className="col-12 col-lg-7 productContent">
            <div className="row">
              <div className="col-12 col-md-12 mb-30">
                <h2>{details.ProductName}</h2>
              </div>
            </div>

            <div className="row mb-50">
              <div className="col-12 col-md-12">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: details.SmallDescription,
                  }}
                />
              </div>
            </div>

            {isLoadingDetailed ? (
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <>
                {/*<div className="row mb-50">
                    <div className="col-12 col-md-12 d-flex flex-row justify-content-start">
                      <div className="row">

                        {
                        Object.values(att.reduce((obj, item) => {
                   
                          obj[item.ProductVarationId] ? obj[item.ProductVarationId].AttributeValueName += " " + item.AttributeValueName : (obj[item.ProductVarationId] = { ...item });
                          return obj;
                        }, {})).map((att, index) => (
                          <div className="col-4" key={index} style={{ backgroundColor: att.ProductVarationId == params.ProductVariationId  ? '#ff0000b3' : 'transparent' , borderRadius: '20px' }}>
                         
                            <Link
                              to={"/DetailedProduct/" + details.ProductName.replace(/ /g, "-") + "/" + params.ProductId + "/" + att.ProductVarationId}
                              onClick={() => setSelectedOption(att.ProductVarationId)}
                            >
                              <p style={{ color: att.ProductVarationId == params.ProductVariationId  ? 'white' : 'black', paddingTop: '20px', textAlign: 'center',  }}>{splitFirstOccurrence(att.AttributeValueName, " ").remainder.join(" ") ? splitFirstOccurrence(att.AttributeValueName, " ").remainder.join(" ") + "-" + splitFirstOccurrence(att.AttributeValueName, " ").first : "" + splitFirstOccurrence(att.AttributeValueName, " ").first}</p>
                            </Link>
                         
                          </div>
                        ))
                        }

                      </div>
                    </div>
                  </div>*/}






                <div className="row">
                  {attributes &&
                    attributes.map((item) => (
                      <div className="col-lg-6">
                        <div className="mt-4">
                          <h5 className="fs-15 mb-3">{item.attributeName} :</h5>
                          <div className="d-flex flex-wrap gap-2">
                            {item.attributeValues.map((attVal) => {
                              return (
                                <div
                                  key={attVal.attributeValueId}
                                  onClick={() => {
                                    // if (
                                    //   variants.some((variant) =>
                                    //     variant.AssignAttributeValueToProductVariations.some(
                                    //       (value) =>
                                    //         value.AttributeValue
                                    //           .AttributeValueId ===
                                    //           attVal.attributeValueId &&
                                    //         value.AttributeValue.AttributeId ===
                                    //           item.attributeId
                                    //     )
                                    //   )
                                    // ) {
                                    handleAttributeValueClick(
                                      item.attributeId,
                                      attVal.attributeValueId
                                    );
                                    // }
                                  }}
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  data-bs-placement="top"
                                  data-bs-original-title="Out of Stock"
                                >
                                  {/* selectedAttributeValues?.some(
                                      (value) =>
                                        value.attributeId ===
                                          item.attributeId &&
                                        value.attributeValueId ===
                                          attVal.attributeValueId
                                    )
                                      ? ""
                                      : "", */}
                                  {item.attributeName.toLowerCase() ===
                                    "color" ? (
                                    <div className="color-attribute-outer" style={{
                                      border: "2px solid #d01214",
                                      opacity: !variants?.some((variant) =>
                                        variant.AssignAttributeValueToProductVariations?.some(
                                          (value) =>
                                            value.AttributeValue
                                              .AttributeValueId ===
                                            attVal.attributeValueId &&
                                            value.AttributeValue
                                              .AttributeId ===
                                            item.attributeId
                                        )
                                      ) ? "0.3" : "1"

                                    }}>
                                      <div className="color-attribute-inner" style={{
                                        backgroundColor: `${attVal.colorValue}`, opacity: !variants?.some((variant) =>
                                          variant.AssignAttributeValueToProductVariations?.some(
                                            (value) =>
                                              value.AttributeValue
                                                .AttributeValueId ===
                                              attVal.attributeValueId &&
                                              value.AttributeValue
                                                .AttributeId ===
                                              item.attributeId
                                          )
                                        ) ? "0.5" : "1"
                                      }}>
                                        {/* {
                                          !variants?.some((variant) =>
                                            variant.AssignAttributeValueToProductVariations?.some(
                                              (value) =>
                                                value.AttributeValue
                                                  .AttributeValueId ===
                                                  attVal.attributeValueId &&
                                                value.AttributeValue
                                                  .AttributeId ===
                                                  item.attributeId
                                            )
                                          ) && <label style={{fontSize: 'x-Large'}}>X</label>
                                        } */}
                                      </div>
                                    </div>
                                  ) : (
                                    <label
                                      className="btn btn-soft-primary p-2 d-flex justify-content-center align-items-center"
                                      htmlFor={attVal.attributeValueId}
                                      style={{
                                        backgroundColor:
                                          selectedAttributeValues?.some(
                                            (value) =>
                                              value.attributeId ===
                                              item.attributeId &&
                                              value.attributeValueId ===
                                              attVal.attributeValueId
                                          )
                                            ? "#d01214"
                                            : "",
                                        // textDecoration: variants?.some((variant) =>
                                        //   variant.AssignAttributeValueToProductVariations?.some(
                                        //     (value) =>
                                        //       value.AttributeValue
                                        //         .AttributeValueId ===
                                        //       attVal.attributeValueId &&
                                        //       value.AttributeValue
                                        //         .AttributeId ===
                                        //       item.attributeId
                                        //   )
                                        // ) ? "" : "line-through",
                                        color: selectedAttributeValues?.some(
                                          (value) =>
                                            value.attributeId ===
                                            item.attributeId &&
                                            value.attributeValueId ===
                                            attVal.attributeValueId
                                        )
                                          ? "white"
                                          : variants?.some((variant) =>
                                            variant.AssignAttributeValueToProductVariations?.some(
                                              (value) =>
                                                value.AttributeValue
                                                  .AttributeValueId ===
                                                attVal.attributeValueId &&
                                                value.AttributeValue
                                                  .AttributeId ===
                                                item.attributeId
                                            )
                                          )
                                            ? ""
                                            : "gray",
                                      }}
                                    >
                                      {attVal.attributeValue}
                                    </label>
                                  )}
                                </div>

                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="content">
                      <p
                        className="sub"
                        dangerouslySetInnerHTML={{ __html: plainText }}
                      />
                      {/* <p>{details.Description}</p> */}
                      {
                        variant && (
                          variant.SalePrice !== 0 ? (
                            <div className="row mb-50">
                              <div className="col-8 col-md-6">
                                <label className="oldPrice">
                                  Was: <strike>${variant.RegularPrice}</strike>
                                </label>
                                <span className="discount">
                                  -{" "}
                                  {(
                                    100 -
                                    (100 * variant.SalePrice) / variant.RegularPrice
                                  ).toFixed(2) + "%"}
                                </span>
                              </div>
                              <div className="col-4 col-md-6 text-end">
                                <label className="price">
                                  ${variant.SalePrice}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className="row mb-50">
                              <div className="col-6"></div>
                              <div className="col-6 text-end">
                                <label className="price">
                                  ${variant.RegularPrice}
                                </label>
                              </div>
                            </div>
                          )
                        )

                      }
                      {/*{details.SalePrice !== 0 ? (
                        <div className="row mb-50">
                          <div className="col-8 col-md-6">
                            <label className="oldPrice">
                              Was: <strike>${details.RegularPrice}</strike>
                            </label>
                            <span className="discount">
                              -{" "}
                              {(
                                100 -
                                (100 * details.SalePrice) / details.RegularPrice
                              ).toFixed(2) + "%"}
                            </span>
                          </div>
                          <div className="col-4 col-md-6 text-end">
                            <label className="price">
                              ${details.SalePrice}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div className="row mb-50">
                          <div className="col-6"></div>
                          <div className="col-6 text-end">
                            <label className="price">
                              ${details.RegularPrice}
                            </label>
                          </div>
                        </div>
                      )}*/}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div
                className="col-6 col-md-4 mb-3"
                key={details.ProductVariationId}
              >
                <div className="item">
                  <div className="imgBox">
                    {/* <Link to={"/DetailedProduct/" + details.ProductName.replace(/ /g, "-") + "/" + params.ProductId + "/" + params.ProductVariationId}>
                          <img id="test" src="" alt={details.ProductName} />
                        </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-8 col-md-10">
                <button
                  className={((variant == null) || (variant != null && !variant.IsInStock)) ? "cartBtn disabled" : "cartBtn "} // Apply different class based on stock availability
                  onClick={() =>
                    ((variant != null && variant.IsInStock)) &&
                    HandleSubmit(params.ProductId, params.ProductVariationId)
                  } // Only call HandleSubmit if item is in stock
                  disabled={((variant == null) || (variant != null && !variant.IsInStock))} // Disable button if item is out of stock
                >
                  {(variant != null && variant.IsInStock) ? "Add to Cart " : "Out of Stock"}
                  <span className="float-end">
                    <AiOutlinePlusCircle />
                  </span>
                </button>
              </div>
              <div className="col-4 col-md-2">
                <span className="quantity">Qty: </span>
                <input
                  className="quantityBox"
                  type="number"
                  min="1"
                  placeholder="1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container lowStock mb-50">
        <div className="row titleBar mt-0">
          <div className="col-8 col-md-10">
            <h3>
              <span>You May</span> Also Like
            </h3>
          </div>
          <div className="col-4 col-md-2 text-right">
            <Link to="/">
              View All
              <span>
                <img src={rightRedArrow} alt="Right Arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="row tabSection">
          <Swiper
            className="productsSlider"
            spaceBetween={50}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              800: {
                slidesPerView: 2,
              },
              1100: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              },
              1300: {
                slidesPerView: 4,
              },
            }}
          >
            {crossSell.map((product, index) => {
              return (
                <SwiperSlide>
                  <div className="item" key={product.ProductId}>
                    <div className="imgBox">
                      {/* Image */}
                      <Link
                        to={
                          "/DetailedProduct/" +
                          product.ProductName.replace(/ /g, "-") +
                          "/" +
                          product.ProductId +
                          "/" +
                          product.DefaultVariation.ProductVariationId
                        }
                      >
                        <img
                          src={
                            Global.PHOTO_URL + product.DefaultVariation.Image1
                          }
                          alt={product.ProductName}
                        />
                        {/* Overlay "Out of Stock" label when out of stock */}
                        {!product.InStock && (
                          <div className="out-of-stock-overlay">
                            Out of Stock
                          </div>
                        )}
                      </Link>
                    </div>
                    {/* Content */}
                    <div className="content">
                      {/* Product details */}
                      <Link
                        to={
                          "/DetailedProduct/" +
                          product.ProductName.replace(/ /g, "-") +
                          "/" +
                          product.ProductId +
                          "/" +
                          product.DefaultVariation.ProductVariationId
                        }
                      >
                        <h5>{product.ProductName}</h5>
                      </Link>
                      {/* Add to cart button */}
                      {product.DefaultVariation.SalePrice === 0 ? (
                        <div>
                          <div>
                            <div className="pricenormal">
                              ${product.DefaultVariation.RegularPrice}{" "}
                              <p
                                className="price"
                                style={{ minHeight: "50px" }}
                              >
                                <span className="off"></span>
                                <span className="was">
                                  <span className="fw-medium"></span>{" "}
                                  <strike></strike>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="text-center">
                            <Link
                              to={
                                "/DetailedProduct/" +
                                product.ProductName.replace(/ /g, "-") +
                                "/" +
                                product.ProductId +
                                "/" +
                                product.DefaultVariation.ProductVariationId
                              }
                            >
                              <span className="btn btn-default choose-selection-button">
                                Add to cart
                              </span>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <>
                          <p className="price">
                            ${product.DefaultVariation.SalePrice}{" "}
                          </p>
                          <p>
                            <span className="off">
                              -
                              {product.DefaultVariation.Percentage.toFixed(2) +
                                "%"}
                            </span>
                            <span className="was">
                              <span className="fw-medium">Was:</span>{" "}
                              <strike>
                                ${product.DefaultVariation.RegularPrice}
                              </strike>
                            </span>
                          </p>
                          <div className="text-center">
                            <Link
                              to={
                                "/DetailedProduct/" +
                                product.ProductName.replace(/ /g, "-") +
                                "/" +
                                product.ProductId +
                                "/" +
                                product.DefaultVariation.ProductVariationId
                              }
                            >
                              <span className="btn btn-default choose-selection-button">
                                Add to cart
                              </span>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default DetailedProduct;
