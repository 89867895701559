import React, { useState } from 'react'
import newsletter from "../../images/icons/newsletter.png";
import footerLogo from "../../images/footerLogo.png";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { BsEnvelope, BsFillTelephoneFill, BsFillGridFill, BsFillPersonFill } from "react-icons/bs";
import { FiHome } from "react-icons/fi";
import { ImCart } from "react-icons/im";
import { HiHeart } from "react-icons/hi";
import { Global } from '../../Variables/Global';
import { toast } from 'react-toastify';

const Footer = (props) => {
    const [emailNewsLetter, setEmailNewsLetter] = useState("");
    var emailregx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    function isValid() {
        if (emailNewsLetter === "") {
            toast.error("please Provide your Email");
            return false;
        } else if (!emailregx.test(emailNewsLetter)) {
            toast.error("Bad email format");
        } else {
            return true;
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValid()) {
            fetch(Global.API_URL + "NewsLetter/SubscribeToNewsLetter", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": Global.API_URL,
                },
                body: JSON.stringify({
                    Email: emailNewsLetter,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        toast.success("Subscribed Successfully");
                        return response.json();
                    }
                    return response.text().then((text) => {
                        throw new Error(text);
                    });
                })
        }
    }

    return (
        <>
            <div className='d-none d-md-block desktopFooter'>
                <div className="newsLetterSection">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-6">
                                <img src={newsletter} alt="newsletter" />
                                <span>Sign Up To Our Newsletter</span>
                            </div>
                            <div className="col-12 col-lg-6 form">
                                <form onSubmit={handleSubmit}>
                                    <input type="text" placeholder="Enter your email address" onChange={(e) => setEmailNewsLetter(e.target.value)} />
                                    <button type='submit'>Sign Up</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 logos">
                                <img src={footerLogo} alt="Footer Logo" className="logo" />
                                <span className="aboutTitle">About Play Megastore</span>
                                <p>
                                    You’re one Stop Shop for all the latest and most exclusive smart Gadgets & Technologies.</p>
                                <p>Our services :</p>
                                <p>-Fast service Phone Maintenance </p>
                                <p>-Recharge, Internet and Gaming Cards </p>
                                <p>-Airpods Cleaning</p>
                                <p>-Engraving and Printing on IQOS/Airpods/Phone Cases   </p>


                            </div>
                            <div className="col-12 col-md-6 info">
                                <h3>Categories</h3>
                                <div className="row">
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="/Category/Laptops/1">Laptops</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Camera/8">Cameras</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Phones/25">Phones</Link>
                                            </li>
                                            <li>
                                                <Link to="/Categpry/Wearables/35">Wearables</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Smart Gadgets/6">Smart Gadgets</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="/Category/ Cables/60"> Cables</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Watch bands/43">Watch bands</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Light/7">Light</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Gaming/12">Gaming</Link>
                                            </li>
                                            <li>
                                                <Link to="/Category/Accessories/64">Accessories</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 logos">
                                <h3>Social Media</h3>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/playmegastore">
                                            <AiFillFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/playmegastorelb">
                                            <AiOutlineInstagram />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <AiOutlineTwitter />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:play@playmegastore.com">
                                            <BsEnvelope />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+96181515151">
                                            <BsFillTelephoneFill />
                                        </a>
                                    </li>
                                </ul>
                                
                                
                            </div>
                           
                            
                          
                            {/* <div className="col-12 col-md-2 info">
                                <h3>Customer Care</h3>
                                <ul>
                                    <li>
                                        <Link to=""> My Account</Link>
                                    </li>
                                    <li>
                                        <Link to="">Order Tracking</Link>
                                    </li>
                                    <li>
                                        <Link to="">Customer Service</Link>
                                    </li>
                                    <li>
                                        <Link to="">Returns / Exchange</Link>
                                    </li>
                                    <li>
                                        <Link to="">F.A,Q.S</Link>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-6 text-left">
                                <div className="bottomText">Powered by Vision & More</div>
                            </div>
                            <div className="col-6 termsAndConditions text-right">
                                <Link
                                    to={"/TermsAndConditions"} >
                                    <h5 className="underline">Terms and Conditions</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <div className='d-md-none mobileFooter'>
                <footer>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='d-flex justify-content-between'>
                                    <div><Link to="/" className={props.footerActive == 1 ? "active" : ""}><FiHome /></Link></div>
                                    <div><Link to="/Category-List" className={props.footerActive == 2 ? "active" : ""}><BsFillGridFill /></Link></div>
                                    <div><Link to="/Cart" className={props.footerActive == 3 ? "active" : ""}><ImCart /></Link></div>
                                    <div><Link to="/Wishlist" className={props.footerActive == 4 ? "active" : ""}><HiHeart /></Link></div>
                                    <div><Link to="/Profile" className={props.footerActive == 5 ? "active" : ""}><BsFillPersonFill /></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer
