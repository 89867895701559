import { Link, Navigate, useParams } from "react-router-dom";
import addcart from "../../images/icons/addcart.png";
// import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";
import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import axios from "axios";
import { FaList } from 'react-icons/fa'
import { BsGrid3X3GapFill, BsGridFill } from 'react-icons/bs'
import { BiFilterAlt } from "react-icons/bi";
import { FiChevronLeft, FiX } from "react-icons/fi";
import Util from "../../Classes/Util";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import FormRange from "react-bootstrap/esm/FormRange";
import wishListIcon from "../../images/icons/wishlist.png";
import Product from "./Product";
import Cookies from "js-cookie";


const ListProducts = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceInterval, setPriceInterval] = useState([]);
  const [isLogged, setIsLogged] = useState(false);
  const [filters, setFilters] = useState([
    {
      filterName: "",
      filterValues: [],
    },
  ]);
  const [WishListItems, setWishListItems] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedAttribute, setSelectedAttributes] = useState([]);
  const [selectedAllAttributes, setSelectedAllAttributes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [totalPage, setTotalPage] = useState([]);
  const [wishListClicked, setWishListClicked] = useState(false);
  const [productsCount, setProductsCount] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  let LIMIT = 15;

  function getIntervals(min, max, nbIntervalls) {
    max -= min;
    var size = Math.round((max - 1) / nbIntervalls);
    var result = [];

    for (let i = 0; i < nbIntervalls; i++) {
      var inf = i + i * size;
      var sup = inf + size < max ? inf + size : max;

      result.push([inf + min, sup + min]);
      if (inf >= max || sup >= max) break;
    }
    return result;
  }

  const getFilters = async (index) => {
    setIsLoading(true)
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    fetch(
      Global.API_URL +
      "AttributeValues/GetFiltersByCategory"
      ,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.API_URL,
        },
        body: JSON.stringify({
          categoryId: params.CategoryId,
          pageId: index,
          count: LIMIT,
          countryId: 125,
          groupId: groupId,
          attributes: [],
          categories: [params.CategoryId],
          offset: offset,
          prices: [],
        }),
      }
    )
      .then((Response) => Response.json())
      .then((Result) => {
        var array = [
          {
            filterName: "",
            filterValues: [],
          },
        ];
        Result.Attributes.forEach((filter) => {
          array.push({
            filterName: filter.AttributeName,
            filterValues: filter.AttributeValues,
          });
        });
        array.push({
          filterName: "Category",
          filterValues: Result.Categories,
        });
        array = array.filter((a) => a.filterName !== "");
        let intervals = getIntervals(
          Result.Prices.MinimumPrice,
          Result.Prices.MaximumPrice,
          5
        );
        array.push({
          filterName: "Price",
          filterValues: intervals,
        });
        setPriceInterval(intervals);
        setFilters(array);
      });
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
       
    //   })
  }

  const getProductByCategoryId = async (index) => {
    var groupId;
    if (Cookies.get("WishList") != undefined) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    fetch(Global.API_URL + "Products/GetProductsByCategoryIdWeb", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        pageId: index,
        count: LIMIT,
        offset: offset,
        attributes: [],
        categories: [],
        prices: [],
      }),
    })
      .then((Response) => {
        if (Response.ok) return Response.json();
        else throw Error("Did Not Receive expected data");
      })
      .then((Result) => {
        setCategoryProducts(Result.finalList);
        setProductsCount(Result.count);
      })
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
       
    //   })
  }
  // const getProductByCategoryId = async (index) => {
  //   var groupId;
  //   if (localStorage.getItem("WishList")) {
  //     groupId = 4
  //   } else {
  //     groupId = 3
  //   }
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   var country;
  //   fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((Response) => {
  //       if (Response.ok) {
  //         return Response.json();
  //       } else {
  //         return ""
  //       }
  //     })
  //     .then((Result) => {
  //       country = Result.CountryId
  //       const offset = new Date().getTimezoneOffset();
  //       fetch(Global.API_URL + "Products/GetAllProductsVariationsByCategoryId", {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": Global.API_URL,
  //         },
  //         body: JSON.stringify({
  //           categoryId: params.CategoryId,
  //           pageId: index,
  //           count: LIMIT,
  //           offset: offset,
  //           attributes: [],
  //           categories: [],
  //           prices: [],
  //         }),
  //       })
  //         .then((Response) => {
  //           if (Response.ok) return Response.json();
  //           else throw Error("Did Not Receive expected data");
  //         })
  //         .then((Result) => {
  //           setCategoryProducts(Result)
  //         })
  //     })
  // }


  const fetchFilteredProductCount = () => {

    // GetProductsVariationCountByCategory
    fetch(Global.API_URL + "Products/GetProductsCountByCategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        attributes: [],
        categories: [],
        prices: [],
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        var array = [],
          total = Result / LIMIT;
        for (var i = 0; i < total; i++) {
          array.push({
            index: i,
            pageNumber: i + 1,
          });
        }
        setTotalPage(array);

      });

    setIsLoading(false)
  };

  // const fetchFilteredProductCount = () => {
  //   fetch(Global.API_URL + "Products/GetProductsCountByCategory", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       categoryId: params.CategoryId,
  //       attributes: [],
  //       categories: [],
  //       prices: [],
  //     }),
  //   })
  //     .then((Response) => {
  //       if (Response.ok) {
  //         return Response.json();
  //       }
  //     })
  //     .then((Result) => {
  //       var array = [],
  //         total = Result / LIMIT;

  //       for (var i = 0; i < total; i++) {
  //         array.push({
  //           index: i,
  //           pageNumber: i + 1,
  //         });
  //       }
  //       setTotalPage(array);
  //     });
  //   setIsLoading(false)
  // };

  const pageChange = (index) => {
    setPage(index);
    getProductByCategoryId(index);
    window.scrollTo(0, 0);
  };

  const FilterProduct = async (e) => {
    const offset = new Date().getTimezoneOffset();
    var ColorArray = [...selectedColors];
    var SizeArray = [...selectedSizes];
    var CategoryArray = [...selectedCategory];
    var intervalprice = [...selectedPrice];
    var attributeArray = [...selectedAttribute];

    if (e.target.name === "color") {
      if (e.target.checked) {
        ColorArray.push(e.target.value);
        selectedColors.push(e.target.value);
      } else {
        var index = ColorArray.indexOf(e.target.value);
        if (index !== -1) {
          ColorArray.splice(index, 1);
          selectedColors.splice(index, 1);
        }
      }
    } else if (e.target.name === "size") {
      if (e.target.checked) {
        SizeArray.push(e.target.value);
        selectedSizes.push(e.target.value);
      } else {
        var index = SizeArray.indexOf(e.target.value);
        if (index !== -1) {
          SizeArray.splice(index, 1);
          selectedSizes.splice(index, 1);
        }
      }
    } else if (e.target.name === "category") {
      if (e.target.checked) {
        CategoryArray.push(e.target.value);
        // setSelectedCategory(CategoryArray);
        selectedCategory.push(e.target.value);
      } else {
        var index = CategoryArray.indexOf(e.target.value);
        if (index !== -1) {
          CategoryArray.splice(index, 1);
          // setSelectedCategory(CategoryArray);
          selectedCategory.splice(index, 1);
        }
      }
    } else if (e.target.name === "price") {
      if (e.target.checked) {
        intervalprice.push(e.target.value);
        // setSelectedPrice(intervalprice);
        selectedPrice.push(e.target.value);
      } else {
        var index = intervalprice.indexOf(e.target.value);
        if (index !== -1) {
          intervalprice.splice(index, 1);
          // setSelectedPrice(intervalprice);
          selectedPrice.splice(index, 1);
        }
      }
    } else {
      if (e.target.checked) {
        attributeArray.push(e.target.value);
        // setSelectedAttributes(attributeArray);
        selectedAttribute.push(e.target.value);
      } else {
        var index = attributeArray.indexOf(e.target.value);
        if (index !== -1) {
          attributeArray.splice(index, 1);
          // setSelectedAttributes(attributeArray);
          selectedAttribute.splice(index, 1);
        }
      }
    }
   
    var a = [];
    var priceArray = [];
    intervalprice.map((i) => {
      a.push(i.split(","));
    });
    a.map((m) => {
      priceArray.push({
        startAmount: m[0],
        endAmount: m[1],
      });
    });

    var attribute = [];
    if (ColorArray.length > 0) {
      ColorArray.forEach((c) => {
        attribute.push(c);
      });
    }
    ///testing
    if(SizeArray.length > 0){
      SizeArray.forEach((c) => {
        attribute.push(c);
      })
    }

    ///

    if (attributeArray.length > 0) {
      attributeArray.forEach((c) => {
        attribute.push(c);
      });
    }
    // setSelectedAllAttributes(attribute);
    if (attribute.length > 0) {
      attribute.forEach((b) => {
        selectedAllAttributes.push(b);
      })
    }
    // setPrices(priceArray);
    // setCategoryProducts([]);
    categoryProducts.length = 0;
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4
    } else {
      groupId = 3
    }
    var index = 0;
    setPage(index)

    fetch(Global.API_URL + "Products/GetProductsByCategoryIdWeb", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        pageId: page,
        count: LIMIT,
        attributes: attribute,
        categories: CategoryArray,
        prices: priceArray,
        offset: offset,
        countryId: 125,
        groupId: groupId
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        var newProductArray = [];
        newProductArray = Result.finalList;
        fetchFilteredProductCount(attribute, CategoryArray, priceArray);
        setCategoryProducts(newProductArray);
      });

    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
       


        // fetch(Global.API_URL + "Products/GetAllProductsVariationsByCategoryId", {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     "Access-Control-Allow-Origin": Global.API_URL,
        //   },
        //   body: JSON.stringify({
        //     categoryId: params.CategoryId,
        //     pageId: page,
        //     count: LIMIT,
        //     attributes: attribute,
        //     categories: CategoryArray,
        //     prices: priceArray,
        //     offset: offset,
        //     countryId: country,
        //     groupId: groupId
        //   }),
        // })
        //   .then((Response) => Response.json())
        //   .then((Result) => {
        //     var newProductArray = [];

        //     newProductArray = Result;
        //     // fetchFilteredProductCount(attribute, CategoryArray, priceArray);
        //     fetch(Global.API_URL + "Products/GetProductsVariationCountByCategory", {
        //       method: "POST",
        //       headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //       body: JSON.stringify({
        //         categoryId: params.CategoryId,
        //         attributes: attribute,
        //         categories: CategoryArray,
        //         prices: priceArray,
        //       }),
        //     })
        //       .then((Response) => {
        //         if (Response.ok) {
        //           return Response.json();
        //         }
        //       })
        //       .then((Result) => {
        //         var array = [],
        //           total = Result / LIMIT;

        //         for (var i = 0; i < total; i++) {
        //           array.push({
        //             index: i,
        //             pageNumber: i + 1,
        //           });
        //         }
        //         setTotalPage(array);
        //       });
        //     setIsLoading(false)
        //     setCategoryProducts(newProductArray);

        //     newProductArray.forEach((t) => {
        //       categoryProducts.push(t);

        //     })

        //   });
      // })
  }

  function openSortList() {
    const box = document.getElementById("SortBox");
    box.classList.add("sortListBoxShow");
  }

  function closeSortList() {
    const box = document.getElementById("SortBox");
    box.classList.remove("sortListBoxShow");
  }

  function openFilterAside() {
    const box = document.getElementById("filterAside");
    box.classList.add("filterAsideBoxShow");
  }

  function closeFilterAside() {
    const box = document.getElementById("filterAside");
    box.classList.remove("filterAsideBoxShow");
  }

  const HandleSubmit = async (ProductId, ProductVariationId) => {
    if (isLogged) {
      const getItem = (Cookies.get('Cart') !== undefined);
      if(getItem){
        // var CartId = Util.decrypt(localStorage.getItem("Cart"));
        var CartId = Cookies.get("Cart");
        const token = Cookies.get("CustomerToken");
      let Response = await fetch(Global.API_URL + "Cart/AddItemToCart",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },

          body: JSON.stringify({
            CartId: CartId,
            ProductId: ProductId,
            VariationId: ProductVariationId,
            Quantity: 1
          })
        }
      ).then((response) => {
        if (response.ok) {
          toast.success("Item added to cart");
          return response.json();
        } else {
          return Response.text().then((text) => {
            throw new Error(text);
          });
        }
      }).catch((error) => {
        toast.error("Could Not Add");
      })
      }


      
    } else {
      navigate('/UserAuthentication');
    }
  }

  const HandleAddToWishList = async (ProductId, ProductVariationId) => {
    if (isLogged) {
      setWishListClicked(!wishListClicked);
      const getItem = (Cookies.get('WishList') !== undefined);
      if(getItem){
        // var WishlistId = Util.decrypt(localStorage.getItem("WishList"));
        var WishlistId = Cookies.get("WishList");
        const token = Cookies.get("CustomerToken");
      let Response = await fetch(Global.API_URL + "Wishlist/AddItemToWishlist",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },

          body: JSON.stringify({
            WishlistId: WishlistId,
            ProductId: ProductId,
            VariationId: ProductVariationId,
          })
        }
      ).then((response) => {
        if (response.ok) {
          toast.success("Item added to wishList");
          return response.json();
        } else {
          return Response.text().then((text) => {
            throw new Error(text);
          });
        }
      }).catch((error) => {
        toast.error("Could Not Add");
      })
      }
      
    } else {
      navigate('/UserAuthentication');
    }
  }

  const HandleRemoveFromWishList = async (ProductId) => {
    if (isLogged) {
      setWishListClicked(!wishListClicked);
      const getItem = (Cookies.get('WishList') !== undefined);
      if(getItem){
        // var WishlistId = Util.decrypt(localStorage.getItem("WishList"));
        var WishlistId = Cookies.get("WishList");
        const token = Cookies.get("CustomerToken");
      let Response = await fetch(Global.API_URL + "Wishlist/RemoveItemFromWishlist" + "/" + ProductId + "/" + WishlistId,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          }
        }
      ).then((response) => {
        if (response.ok) {
          toast.success("Item Removed from wishList");
          return response.json();
        } else {
          return Response.text().then((text) => {
            throw new Error(text);
          });
        }
      }).catch((error) => {
        toast.error("Could Not Remove");
      })
      }
      
    } else {
      navigate('/UserAuthentication');
    }
  }


  const getWishListItems = async () => {
    var groupId;
    if (Cookies.get("WishList")) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    const getItem = (Cookies.get('WishList') !== undefined);
      if(getItem){
        // var WishlistId = Util.decrypt(localStorage.getItem("WishList"))
        var WishlistId = Cookies.get("WishList");
        const token = Cookies.get("CustomerToken");
        fetch(Global.API_URL + "Wishlist/GetWishistItems/" + WishlistId + "/" + offset + "/" + 125 + "/" + groupId, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
        }).then((Result) => {
          setWishListItems(Result.WishlistItems);
        });
      }
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
     
       
    //   })

  };


  useEffect(() => {
    if (Cookies.get("CustomerToken") === undefined) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
    props.setHeaderTitle(params.CategoryName)
    props.setFooterActive(2);
    window.scrollTo(0, 0);
    getFilters(0)
    getProductByCategoryId();
    fetchFilteredProductCount();
    getWishListItems();
 
  }, [props, wishListClicked])


  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="container productsList">
          <div className="row d-none d-md-block">
            <div className="col-12 col-md-12">
              <ul className="bread-crumb">
                <li>Home</li>
                <li>/</li>
                <li>{params.CategoryName}</li>
              </ul>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col-12 col-md-12">
              <h1>{params.CategoryName}</h1>
            </div>
          </div>
          <div className="row topFilterLine d-none d-md-flex">
            <div className="col-3">
              <label className="filterTitle">Filters</label>
            </div>
            <div className="col-5">
              <span className="noProducts">{productsCount} Products</span>
              {/* <span className="sortBy">
                <strong>Sort: </strong>
                <select>
                  <option>Relevence</option>
                </select>
              </span> */}
            </div>
            {/* <div className="col-4 listBy text-end">
              <FaList/>
              <BsGridFill/>
              <BsGrid3X3GapFill/>
            </div> */}
          </div>
          <div className="row mobileFilters d-md-none mb-3">
            <div className="col-8">
              {/* <span className="Filtername" onClick={openSortList}><span className="red">Sort: </span>by Relevance</span> */}
              <span className="Filtername ms-3" onClick={openFilterAside}><span className="red">Filter</span> <BiFilterAlt /></span>
            </div>
            {/* <div className="col-4 listByMobile text-end">
              <FaList/>
              <span className="red"><BsGridFill/></span>
              <BsGrid3X3GapFill/> 
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 col-md-3 filterBox d-none d-md-block">
              {
                categoryProducts.length != 0 && (
                  filters.length > 0 && (
                    <Accordion defaultActiveKey="0">
                      {filters.map((option, index) => {
                        return (
                          <Accordion.Item key={index} eventKey={index}>
                            {option.filterValues.length > 0 && (
                              <Accordion.Header>
                                {option.filterName}{" "}
                              </Accordion.Header>
                            )}
                            {option.filterName === "Category" ? (
                              <Accordion.Body>
                                <ul>
                                  {option.filterValues.length > 0 &&
                                    option.filterValues.map((c, index) => {
                                      return (
                                        <li key={index}>
                                          <input
                                            type="checkbox"
                                            value={c.CategoryId}
                                            name="category"
                                            onClick={FilterProduct}
                                            className="d-none"
                                            id="categoryFilter"
                                          />{" "}
                                          <label htmlFor="categoryFilter">{c.CategoryName}</label>
                                        </li>
                                      )
                                    })}
                                </ul>
                              </Accordion.Body>
                            ) : option.filterName === "Color" ? (
                              <Accordion.Body>
                                <ul >
                                  {option.filterValues.length > 0 &&
                                    option.filterValues.map((color,index) => {
                                      return (
                                        <li key={index}>
                                          <div>
                                            <input
                                              className="plCheckbox me-2"
                                              type="checkbox"
                                              value={
                                                color.AttributeValueId
                                              }
                                              name="color"
                                              onClick={FilterProduct}
                                              style={{
                                                backgroundColor:
                                                  color.AttributeValue,
                                              }}
                                            />{" "}
                                            {color.AttributeName}
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Accordion.Body>
                            ) : option.filterName === "GB" ? (
                              <Accordion.Body>
                                <ul >
                                  {option.filterValues.length > 0 &&
                                    option.filterValues.map((size,index) => {
                                      return (
                                        <li key={index}>
                                          <div>
                                            <input
                                              className="plCheckbox me-2"
                                              type="checkbox"
                                              value={
                                                size.AttributeValueId
                                              }
                                              name="size"
                                              onClick={FilterProduct}
                                              style={{
                                                backgroundColor:
                                                size.AttributeValue,
                                              }}
                                            />{" "}
                                            {size.AttributeName}
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Accordion.Body>
                            ) : option.filterName === "Price" ? (
                              <Accordion.Body>
                                <ul >
                                  {priceInterval.map((interval,index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <div>
                                            <input
                                              type="checkbox"
                                              name="price"
                                              value={interval}
                                              onClick={FilterProduct}
                                              // className="d-none"
                                              id="priceFilter"
                                            />
                                            <label htmlFor="priceFilter">${interval[0]} - ${interval[1]}</label>
                                            {/* {` ${interval[0]} - ${interval[1]} `} */}
                                          </div>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </Accordion.Body>
                            ) : null}
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  )
                )
              }
            </div>
            <div className="col-12 col-md-9">
              {
                isLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner"></div>
                  </div>
                ) : (

                  <div className="row">
                    {categoryProducts && categoryProducts.map((product, index) => {
                      return (
                        <Product
                          key={index}
                          product={product}
                          WishListItems={WishListItems}
                          HandleRemoveFromWishList={HandleRemoveFromWishList}
                          HandleAddToWishList={HandleAddToWishList}
                        />
                      )
                    })}
                    {/* </form> */}
                  </div>
                )}
              {totalPage.length > 1 && (
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="pagination">
                      {totalPage.map((p,index) => {
                        return (
                          <div key={index} className={
                            p.index === page
                              ? "pagination-pages active-page"
                              : "pagination-pages"
                          } onClick={() => pageChange(p.index)}>
                            <a>{p.pageNumber}</a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div id="filterAside" className="filterAsideBox">
            <div className="row mb-4">
              <div className="col-12">
                <button type="button" className="closeFilter" onClick={closeFilterAside}><FiChevronLeft /> Filters</button>
              </div>
            </div>
            {filters.length > 0 && (
              <Accordion defaultActiveKey="0">
                {filters.map((option, index) => {
                  return (
                    <Accordion.Item key={index} eventKey={index}>
                      {option.filterValues.length > 0 && (
                        <Accordion.Header>
                          {option.filterName}{" "}
                        </Accordion.Header>
                      )}
                      {option.filterName === "Category" ? (
                        <Accordion.Body>
                          <ul>
                            {option.filterValues.length > 0 &&
                              option.filterValues.map((c, index) => {
                                return (
                                  <li key={index}>
                                    <input
                                      type="checkbox"
                                      value={c.CategoryId}
                                      name="category"
                                      onClick={FilterProduct}
                                      className="d-none"
                                      id="categoryFilter"
                                    />{" "}
                                    <label htmlFor="categoryFilter">{c.CategoryName}</label>
                                  </li>
                                )
                              })}
                          </ul>
                        </Accordion.Body>
                      ) : option.filterName === "Color" ? (
                        <Accordion.Body>
                          <ul >
                            {option.filterValues.length > 0 &&
                              option.filterValues.map((color,index) => {
                                return (
                                  <li key={index}>
                                    <div>
                                      <input
                                        className="plCheckbox me-2"
                                        type="checkbox"
                                        value={
                                          color.AttributeValueId
                                        }
                                        name="color"
                                        onClick={FilterProduct}
                                        style={{
                                          backgroundColor:
                                            color.AttributeValue,
                                        }}
                                      />{" "}
                                      {color.AttributeName}
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </Accordion.Body>
                      ) : option.filterName === "GB" ? (
                        <Accordion.Body>
                          <ul >
                            {option.filterValues.length > 0 &&
                              option.filterValues.map((size,index) => {
                                return (
                                  <li key={index}>
                                    <div>
                                      <input
                                        className="plCheckbox me-2"
                                        type="checkbox"
                                        value={
                                          size.AttributeValueId
                                        }
                                        name="size"
                                        onClick={FilterProduct}
                                        style={{
                                          backgroundColor:
                                          size.AttributeValue,
                                        }}
                                      />{" "}
                                      {size.AttributeName}
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </Accordion.Body>
                      ): option.filterName === "Price" ? (
                        <Accordion.Body>
                          <ul >
                            {priceInterval.map((interval, index) => {
                              return (
                                  <li key={index}>
                                    <input
                                      type="checkbox"
                                      name="price"
                                      value={interval}
                                      onClick={FilterProduct}
                                      // className="d-none"
                                      id="priceFilter"
                                    />
                                    <label htmlFor="priceFilter">${interval[0]} - ${interval[1]}</label>
                                  </li>
                              );
                            })}
                          </ul>
                        </Accordion.Body>
                      ) : null}
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            )}
          </div>
          <div id="SortBox" className="sortListBox">
            <div className="row">
              <div className="col-6">
                <label className="sortTitle">Sort:</label>
              </div>
              <div className="col-6 text-end">
                <button onClick={closeSortList} type="button" className="closeSort"><FiX /></button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ul>
                  <li>Revelence</li>
                  <li>Price: Low to High</li>
                  <li>Price: High to Low</li>
                  <li>Availability</li>
                  <li>Rating</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ListProducts;
