import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import product from "../../../images/products/product9.png";
import { useLocation } from "react-router-dom";
import { Global } from "../../../Variables/Global";
import { useNavigate } from "react-router-dom";
import Util from "../../../Classes/Util";
import axios from "axios";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";

const Checkout = (props) => {
  const location = useLocation();
  const [sessionId, setSessionId] = useState("");
  const [orderNumber, setOrderNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [bankFees, setBankFees] = useState(0);

  const [login, setLogin] = useState(false);
  const [PhoneNumber, setPhoneNumber] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [Notes, setNotes] = useState("Your Notes");
  const [key, setKey] = useState("home");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // const [CustomerId, setCustomerId] = useState(0);
  const navigate = useNavigate();


  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const getCartItems = async () => {
    // setIsLoading(true);
    var groupId;
    if (Cookies.get("WishList") != undefined) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const offset = new Date().getTimezoneOffset();
    // var CartId = Util.decrypt(localStorage.getItem("Cart"))
    var CartId = Cookies.get("Cart");
    const token = Cookies.get("CustomerToken");
 
    // const ID = Cookies.get(("Number"));
    // setCustomerId(ID);

    fetch(
      Global.API_URL +
      "Cart/GetCartItems/" +
      CartId +
      "/" +
      offset +
      "/" +
      125 +
      "/" +
      groupId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((Result) => {
        setCartCount(Result.Count);
        setCartItems(Result.CartItems);

        // Calculate total price after setting cart items
        let totalPrice = 0;
        Result.CartItems.forEach((item) => {
          totalPrice += item.SalePrice != 0 ? item.SalePrice * item.Quantity : item.RegularPrice * item.Quantity;
        });

        // Update total price state
        setBankFees((totalPrice * 0.035).toFixed(2))
        setTotalPrice(totalPrice);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching cart items:', error);
        // Handle error if needed
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getCartItems();
  }, []);


  const getAddressBookDetailsHome = () => {
    // var CustomerId = Util.decrypt(localStorage.getItem("Number"));
    const CustomerId = Cookies.get("Number");
    const token = Cookies.get("CustomerToken");
    fetch(
      Global.API_URL +
      "AddressBook/GetOrderAddressBookByAddressType/" +
      key +
      "/" +
      CustomerId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((Result) => {
        // setAddressBookHome(Result);
        setAddress1(Result.Address1);
        setAddress2(Result.Address2);
        setFirstName(Result.FirstName);
        setLastName(Result.LastName);
        setPhoneNumber(Result.PhoneNumber);
        setEmail(Result.Email);
        fetch(Global.API_URL + "Country/GetCitiyNameById/" + Result.CityId, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((Result) => {
            setCity(Result[0].CityName);
            setCityId(Result[0].CityId);
          });
      })
      .catch((error) => {
        if (error.message.replaceAll('"', "") === "Address book not found") {
        }
      });
  };

  const getTotal = (items) => {
    var totall = 0;
    const total = items.reduce((total, currentItem) => {
      if (currentItem.SalePrice != 0) {
        totall = totall + currentItem.SalePrice * currentItem.Quantity;
      } else {
        totall = totall + currentItem.RegularPrice * currentItem.Quantity;
      }
    }, 0);

    setTotal(totall);
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    if (paymentMethod == '') {
      toast.error("Select a payment method");
    } else {

      setButtonDisabled(true);
      toast.success("Your Order is being procceed. Please wait.");
      var groupId;
      if (Cookies.get("WishList")) {
        groupId = 4;
      } else {
        groupId = 3;
      }
      const offset = new Date().getTimezoneOffset();
      // var CartId = Util.decrypt(localStorage.getItem("Cart"));
      const CartId = Cookies.get("Cart");
      const token = Cookies.get("CustomerToken");

      // CartId==`${CartId}` +"&"+CartCurrency=="Dollar"+"&"+CustomerUsername==`${firstName+" "+lastName}`+"&"+CustomerEmail==`${email}`+"&"+offset +"/"+ country +"/"+ groupId
      fetch(
        Global.API_URL +
        "Order/CreateOrder?" +
        new URLSearchParams({
          CartId: CartId,
          Currency: "Dollar",
          CustomerUsername: `${FirstName + " " + LastName}`,
          Email: Email,
          PhoneNumber: PhoneNumber,
          Address1: Address1,
          Address2: Address2,
          CityId: cityId,
          IsDefault: false,
          IsSamePerson: false,
          Notes: Notes,
          Offset: offset,
          GroupId: groupId,
          CountryCode: "LB",
          CountryId: 125,
          PostalCode: "00000",
          State: "Lebanon",
          AddressType: key,
          PaymentMethodId: paymentMethod == 'delivery' ? 0 : 1,
          Mobile: "0"
        }),
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      ).then(async (response) => {
        if (response.ok) {
          toast.success("Order Successfuly Compeleted");
          return response.json();
        }
        else {
          const errrorMesage = await response.text();
          toast.error(errrorMesage);
        }
      }).then((response) => {

        if (paymentMethod != 'delivery') {
          setSessionId(response.SessionId);
          setOrderNumber(response.OrderNumber);
          handleShowModal();
        }
        else {
          if (response != null) {
            navigate(`/Cart?OrderId=${response.OrderNumber}`);
          }
        }
      });
    }

  };

  useEffect(() => {
    sessionStorage.clear();







    if (Cookies.get("CustomerToken") === undefined) {
      navigate("/UserAuthentication");
    } else {
      props.setHeaderTitle("Checkout");
      props.setFooterActive(3);
      getAddressBookDetailsHome();
      getTotal(cartItems);
    }

    if (window.Checkout) {
      // Initialize Checkout with the obtained session ID
      window.Checkout.configure({
        session: {
          id: sessionId
        }
      });
    } else {
      console.error("Checkout.js is not loaded.");
    }
  }, [key, sessionId]);


  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    const isCancelled = window.confirm("Are you sure you want to cancel the order?");
    if (isCancelled) {
      // Handle cancellation logic here


      setShowModal(false);
      sessionStorage.clear();
      navigate("/Cart");


      const token = Cookies.get("CustomerToken");
      return fetch(Global.API_URL + "Order/UpdateOrderStatus", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          OrderId: orderNumber,
          OrderStatusId: 7 
        }),
      })
        .then((response) => response.json())
        .then((data) => {
        })
        .catch((error) => {
          console.error('Error updating order status:', error);

        });


      // Close the modal
    }
  };

  const handleModalShown = () => {

    window.Checkout.showEmbeddedPage("#hco-embedded");
  };

  return (
    <>

      <div>


        <Modal show={showModal} onHide={handleCloseModal} onShow={handleModalShown}>
          <Modal.Header closeButton>
            <Modal.Title>Play Megastore SARL</Modal.Title>
          </Modal.Header>
          <Modal.Body id="hco-embedded">
            {/* Content of the modal body goes here */}
          </Modal.Body>
          <Modal.Footer>
           <img style={{width:"100px",height:"100px"}} src="https://www.netcommercepay.com/logo/NCseal_S.gif" border="0" alt="NetCommerce Security Seal"></img>

          </Modal.Footer>
        </Modal>
      </div>


      <div className="container Checkout">
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>
                <Link to="/Cart">Cart /</Link>
              </li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <h1>Checkout</h1>
          </div>
        </div>
        <form onSubmit={handleCheckout}>
          <div className="row">
            <div className="col-md-12 col-lg-9 Profile">
              <div className="infoBox mt-0">
                <Tabs
                  activeKey={key}
                  id="controlled-tab-example"
                  className="mb-3"
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey="home" title="Home">
                    <div className="row">
                      <div className="col-12">
                        <label className="title mb-0">
                          1 Personal Information
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Full Name:</label>
                      </div>
                      <div className="col-9">
                        <div className="row">
                          <div className="col-6">
                            <input
                              type="text"
                              value={FirstName}
                              className="inputBox"
                              placeholder="First Name"
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              value={LastName}
                              className="inputBox"
                              placeholder="Last Name"
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        {/* <div className='row'>
                                                <div className='col-3'>
                                                    <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mr.</label>
                                                </div>
                                                <div className='col-3'>
                                                    <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                                                </div>
                                            </div> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Email</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="email"
                          value={Email}
                          className="inputBox"
                          placeholder="Your Email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Phone Number</label>
                      </div>
                      <div className="col-9">
                        <div className="col-12">
                          <PhoneInput
                            placeholder="Type here"
                            className="inputBox"
                            value={PhoneNumber}
                            onChange={setPhoneNumber}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        <label className="title">2 Address</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Address1:</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="inputBox"
                          value={Address1}
                          placeholder="Type Here"
                          onChange={(e) => setAddress1(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">City:</label>
                      </div>
                      <div className="col-9">
                        <select
                          className="inputBoxSelect"
                          onChange={(e) => {
                            setCity(e.target.name);
                            setCityId(e.target.value);
                          }}
                        >
                          <option className="info" value={cityId}>
                            {city.length > 0 ? city : "Choose a city"}
                          </option>
                          <option className="info" value="16124">
                            North
                          </option>
                          <option className="info" value="16125">
                            South
                          </option>
                          <option className="info" value="16126">
                            Beirut
                          </option>
                          <option className="info" value="16127">
                            Mont-Liban
                          </option>
                          <option className="info" value="16128">
                            Bekaa
                          </option>
                          <option className="info" value="16129">
                            Nabatieh
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Address2:</label>
                      </div>
                      <div className="col-9">
                        <textarea
                          placeholder="Type Address"
                          value={Address2}
                          className="textareaBox"
                          cols="4"
                          rows="3"
                          onChange={(e) => setAddress2(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        <label className="title">4 Note</label>
                      </div>
                    </div>
                    <div className="col-3">
                      <label className="infoTitle">Notes:</label>
                    </div>
                    <div className="col-9">
                      <input
                        type="text"
                        className="inputBox"
                        value={Notes}
                        placeholder="Type Here"
                        onChange={(e) => setNotes(e.target.value)}
                        required
                      />
                    </div>
                    <hr />
                  </Tab>
                  <Tab eventKey="work" title="Work">
                    <div className="row">
                      <div className="col-12">
                        <label className="title mb-0">
                          1 Personal Information
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Full Name:</label>
                      </div>
                      <div className="col-9">
                        <div className="row">
                          <div className="col-6">
                            <input
                              type="text"
                              className="inputBox"
                              value={FirstName}
                              placeholder="First Name"
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              className="inputBox"
                              value={LastName}
                              placeholder="Last Name"
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        {/* <div className='row'>
                                                <div className='col-3'>
                                                    <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mr.</label>
                                                </div>
                                                <div className='col-3'>
                                                    <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                                                </div>
                                            </div> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Email</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="email"
                          className="inputBox"
                          value={Email}
                          placeholder="Your Email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Phone Number</label>
                      </div>
                      <div className="col-9">
                        <div className="col-12">
                          <PhoneInput
                            placeholder="Type here"
                            className="inputBox"
                            value={PhoneNumber}
                            onChange={setPhoneNumber}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        <label className="title">2 Address</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Address1:</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="inputBox"
                          value={Address1}
                          placeholder="Type Here"
                          onChange={(e) => setAddress1(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">City:</label>
                      </div>
                      <div className="col-9">
                        <select
                          className="inputBoxSelect"
                          onChange={(e) => {
                            setCity(e.target.name);
                            setCityId(e.target.value);
                          }}
                        >
                          <option className="info" value={cityId}>
                            {city.length > 0 ? city : "Choose a city"}
                          </option>
                          <option className="info" value="16124">
                            North
                          </option>
                          <option className="info" value="16125">
                            South
                          </option>
                          <option className="info" value="16126">
                            Beirut
                          </option>
                          <option className="info" value="16127">
                            Mont-Liban
                          </option>
                          <option className="info" value="16128">
                            Bekaa
                          </option>
                          <option className="info" value="16129">
                            Nabatieh
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="infoTitle">Address2:</label>
                      </div>
                      <div className="col-9">
                        <textarea
                          placeholder="Type Address"
                          value={Address2}
                          className="textareaBox"
                          cols="4"
                          rows="3"
                          onChange={(e) => setAddress2(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        <label className="title">4 Note</label>
                      </div>
                    </div>
                    <div className="col-3">
                      <label className="infoTitle">Notes:</label>
                    </div>
                    <div className="col-9">
                      <input
                        type="text"
                        className="inputBox"
                        value={Notes}
                        placeholder="Type Here"
                        onChange={(e) => setNotes(e.target.value)}
                        required
                      />
                    </div>
                    <hr />
                  </Tab>
                </Tabs>

                <div className="row mt-25 mb-30">
                  <div className="col-12 fontOverride">
                    {/* {login ? (
                                        <>
                                            <span className='infoTitle pointer' onClick={() => setLogin(false)}>Create an Account  </span>
                                            <span className='title pointer'>|  Sign in</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className='title pointer'>Create an Account  </span>
                                            <span className='infoTitle pointer' onClick={() => setLogin(true)}>|  Sign in</span>
                                        </>
                                    )} */}
                  </div>
                </div>
                {/* {login ? (
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Username</label>
                                        </div>
                                        <div className='col-9'>
                                            <input type="email" className='inputBox' placeholder='Type Here' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Password:</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='col-12'>
                                                <input type="Password" className='inputBox' placeholder='Type Here' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                             ) : ( */}
                <>
                  {/* <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Password:</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='col-12'>
                                                <input type="Password" className='inputBox' placeholder='Type Here' />
                                            </div>
                                        </div>
                                    </div> */}
                  {/* <div className='row'>
                                        <div className='col-12'>
                                            <input type="checkbox" id="g" className='inputCheckbox' name='guest' />
                                            <label for="g" className='checkLabel ms-2'>Continue as a guest</label>
                                        </div>
                                    </div> */}
                </>
                {/* )} */}

                {/* <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Country:</label>
                                </div>
                                <div className='col-9'>
                                    <select className='inputBoxSelect'>
                                        <option className='info' selected disabled>-- Choose a Country --</option>
                                        <option className='info'>Country</option>
                                    </select>
                                </div>
                            </div> */}

                {/* <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Zip Code:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div> */}
                {/* <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Postal Code:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div> */}

                {/* <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>Or Saved Address:</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses p-0'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='address' /> Home</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='address' /> Work</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='address' /> Work 2</li>
                                    </ul>
                                </div>
                            </div> */}
                {/* <hr /> */}
                {/* <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>3 Shipping Method:</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses p-0'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='shippping' /> Aramex</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='shippping' /> Dhl</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='shippping' /> Shop Pickup</li>
                                    </ul>
                                </div>
                            </div> */}

                {/* <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>5 Payment Method</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses p-0'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='payment' /> Cash On Delivery</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='payment' /> Paypal</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='payment' /> Card</li>
                                    </ul>
                                </div>
                            </div> */}
                {/* <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Name on Card:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Card Pin:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div> */}
              </div>
            </div>
            <div className="col-md-12 col-lg-3 Profile">
              <div className="infoBox mt-0">
                <div className="row mb-30">
                  <div className="col-12">
                    <label className="sideTitlep">
                      {cartCount} {cartCount > 1 ? "Items" : "Item"}
                    </label>
                  </div>
                </div>
                {cartItems.map((item) => {


                  return (

                    <div key={item.ProductId} className="row mb-50">
                      <div className="col-4">
                        <img
                          src={Global.PHOTO_URL + item.Image}
                          className="CheckoutImg"
                        />
                      </div>
                      <div className="col-8">
                        <p className="itemName">
                          {item.ProductName}
                          <span> x{item.Quantity}</span>
                        </p>
                        {item.SalePrice == 0 ? (
                          <span className="itemPrice float-end">
                            ${item.RegularPrice * item.Quantity}
                          </span>
                        ) : (
                          <span className="itemPrice float-end">
                            ${item.SalePrice * item.Quantity}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
                {/* <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep'>Subtotal</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 1,010.00</label>
                                </div>
                            </div> */}
                {/* <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep'>Shipping</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 2.00</label>
                                </div>
                            </div> */}
                <hr />
                {paymentMethod === 'online' ?
                <div className="row">
                  <p className="itemName" style={{ textAlignLast: "right", color: "black" }}>
                    Bank Fees (3.5%)
                    <span> {Math.ceil(bankFees)}</span>
                  </p>
                </div>
                : null}

                <div className="row">
                  <p className="itemName" style={{ textAlignLast: "right", color: "black" }}>
                    Total
                    <span> {paymentMethod === 'online' ? (parseFloat(Math.ceil(totalPrice)) + Math.ceil(bankFees)): totalPrice}</span>
                  </p>
                </div>



                <div>
                  {cartCount > 0 && (
                    <div>
                      <h5>Select Payment Method:</h5>
                      <div >
                        <label className="payment-option">
                          <input type="radio" value="online"
                            checked={paymentMethod === 'online'}
                            onChange={handlePaymentMethodChange}
                            className="payment-radio"
                          />
                          Pay Online
                        </label>
                      </div>
                      <div>
                        <label className="payment-option">
                          <input
                            type="radio"
                            value="delivery"
                            checked={paymentMethod === 'delivery'}
                            onChange={handlePaymentMethodChange}
                            className="payment-radio"
                          />
                          Cash on Delivery
                        </label>
                      </div>
                      {/* <button onClick={() => console.log("Selected Payment Method:", paymentMethod)}>
      Continue
    </button> */}
                    </div>
                  )}

                  {/* <button onClick={() => console.log("Selected Payment Method:", paymentMethod)}>
        Continue
      </button> */}
                </div>

                {/* {cityId == 16126 ? (
                  <div className="row">
                    <div className="col-6">
                      <label className="sideTitlep red">Total</label>
                    </div>
                    <div className="col-6 text-end">
                      <label className="price red">$ {total}</label>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <label className="sideTitlep red">SubTotal</label>
                      </div>
                      <div className="col-6 text-end">
                        <label className="price red">$ {total}</label>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-6">
                        <label className="sideTitlep red">Delivery</label>
                      </div>
                      <div className="col-6 text-end">
                        <label className="price red">$3</label>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-6">
                        <label className="sideTitlep red">Total</label>
                      </div>
                      <div className="col-6 text-end">
                        <label className="price red">${total + 3}</label>
                      </div>
                    </div>
                  </>
                )} */}
              </div>

              <div className="row">
                <div className="col-12">
                  <button
                    // onClick={() => ()}
                    disabled={buttonDisabled}
                    className="outBtn"
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Checkout;
