import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="WordSection1" style={{ padding: 20, marginTop: 120 }}>
        <h1>Terms and Conditions</h1>

        <h2>Acceptance</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          By placing an order with Play MegaStore for pick-up and/or delivery,
          you agree to all of the terms and conditions stated herein.{" "}
        </p>
        <h2>Applicable Law</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          The laws of the province of Ontario will govern these disclaimers,
          terms and conditions, without regard to any conflict of law
          provisions. You also agree and submit to the exclusive jurisdiction
          and venue of the courts of the province of Ontario and acknowledge
          that you do so voluntarily. The parties agree to use their best
          efforts to resolve any dispute that may arise through good faith
          negotiations. The parties acknowledge their desire that any
          irreconcilable dispute or difference shall be resolved by mediation
          involving the appointment of a mutually acceptable mediator. The cost
          of any such mediation is to be shared equally by the parties. This is
          without prejudice to any other right or entitlement that they may
          have. If any provision of these Terms and Conditions is found to be
          unlawful, void or unenforceable, then such provision is deemed
          severable and will not affect the validity or enforceability of any of
          the remaining provisions.{" "}
        </p>
        <h2>Indemnity</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          You shall indemnify and hold harmless Play MegaStore against all
          liabilities, losses, claims, damages, costs and expenses of any nature
          whatsoever incurred as a consequence of your non-observance of any
          regulation of whatever nature which you are required to observe with
          regard to or in connection with the carriage of the goods shipped.{" "}
        </p>
        <h2>Payment</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          Invoices are payable upon receipt. Any discounts applied to an order
          are subject to payment being received on time. Where payment for a
          shipment has not been received within 30 days of invoice, Play
          MegaStore has the option of recalculating all charges to those shown
          in the current tariff. Overdue balances are subject to interest
          calculated at 2% per month (19.6% per annum) which shall accrue and be
          payable to Play MegaStore on all outstanding monies. This is in
          addition to any legal rights and remedies available to Play MegaStore.{" "}
        </p>
        <h2>Delay</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          Unless agreed to otherwise in writing, Play MegaStore shall not be
          liable for any special consequential or other damages caused by mere
          delay in the delivery of a shipment.
        </p>
        <h2>Limits of Liability</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          Play MegaStore shall not be liable for any loss, damage, destruction
          or unreasonable delays arising from factors beyond the control of Play
          MegaStore including but not limited to the following causes: acts of
          God, the Queen's or public enemies, awful weather, bad traffic
          conditions, riots, strikes, war or terrorism, authority of law, defect
          or inherent vice in the goods shipped, act or default of the shipper
          or owner of the goods, nuclear reaction, radiation or radioactive
          contamination.{" "}
        </p>

        <h2>Declared Value of Shipments And Insurance</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          The shipper may declare the value of a shipment for the sake of record
          keeping. However, unless the shipper indicates YES in the Insurance
          prompt during order placement, Play MegaStore's liability for any
          loss, damage or injury to a shipment shall not exceed the lesser of
          $1.50 per pound or $50.00. If the shipper indicates YES in the
          Insurance prompt during order placement, an additional charge equal to
          $1 per $100 or part thereof on declared value in excess of $200 will
          be applied. Insurance on declared values of over $1000 must be
          pre-approved by a Play MegaStore office employee. Play MegaStore
          cannot insure the following items under any circumstances: tenders,
          antiques, currency, precious metals or stones, glass or glassware,
          ceramics, household goods, travelers cheques, works of art, liquor,
          wine, spirits, tobacco, cigars, cigarettes, stocks, bonds,
          certificates, bullion, money orders, stamps, jewelry, watches,
          cashier's cheques, plants, liquids, perishables, furs. All computer,
          electronic goods and the like must be in original manufacturer's
          packaging and are not covered unless certified as operating before
          pick-up, and unless packaging is damaged in transit and so noted upon
          delivery. Claims made on shipments signed for in good order will not
          be processed. The claimant agrees that notwithstanding any disclosure
          of the nature or value of the goods, the amount of any loss or damage,
          including consequential, incidental or indirect damage, loss of
          earnings or profits resulting from the loss of or damage to the goods,
          shall not exceed the maximum liability of Play MegaStore set out
          above.{" "}
        </p>

        <h2>Notice of Loss or Damage</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          Notice of loss or damage must be given to Play MegaStore within
          twenty-four (24) hours after such loss or damage becomes known and in
          writing within ten (10) days after the order was placed. Play
          MegaStore will accept no liability for loss or damage unless written
          notice of such loss or damage is received within ten days after the
          order was placed.{" "}
        </p>

        <h2>Fuel Surcharge</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          The fuel surcharge applies to the cost of all orders using vehicles
          for delivery based on the current fuel prices.{" "}
        </p>

        <h2>Security</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline. Wherever we collect sensitive information
          (such as credit card data), that information is encrypted and
          transmitted to us in a secure way. You can verify this by looking for
          a closed lock icon at the bottom of your web browser, or looking for
          "https" at the beginning of the address of the web page. While we use
          encryption to protect sensitive information transmitted online, we
          also protect your information offline. Only employees who need the
          information to perform a specific job (for example, billing or
          customer service) are granted access to personally identifiable
          information. The computers/servers in which we store personally
          identifiable information are kept in a secure environment. If you feel
          that we are not abiding by this privacy policy, you should contact us
          immediately via telephone at Toll Free +961 81 515 151 or via email
          play@playmegastore.com.{" "}
        </p>

        <h2>Your Access to and Control Over Information</h2>
        <p style={{ fontWeight: "400", fontSize: 18, color: "black" }}>
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via the email address or
          phone number given on our website: - See what data we have about you,
          if any. - Change/correct any data we have about you. - Have us delete
          any data we have about you. - Express any concern you have about our
          use of your data.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
