import addcart from "../../../images/icons/addcart.png";
import product from "../../../images/products/product.png";
import product1 from "../../../images/products/product8.png";
import product2 from "../../../images/products/product9.png";
import activeWishListIcon from "../../../images/icons/inWishList.png";
import { useEffect, useState } from "react";
import { FaList } from "react-icons/fa";
import { BsGrid3X3GapFill, BsGridFill } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Global } from "../../../Variables/Global";
import Util from "../../../Classes/Util";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const WishList = (props) => {
  const [WishListItems, setWishListItems] = useState([]);
  const [WishListCount, setWishListCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();

  const getWishListItems = async () => {
    setIsLoading(true);
    var groupId;
    if (Cookies.get("WishList")) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    // var WishlistId = Util.decrypt(localStorage.getItem("WishList"))
    const WishlistId = Cookies.get("WishList");
    const token = Cookies.get("CustomerToken");
    fetch(Global.API_URL + "Wishlist/GetWishistItems/" + WishlistId +"/"+ offset +"/"+ 125 +"/"+ groupId,{
      method: "GET",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((Result) => {
      setWishListCount(Result.Count);
      setWishListItems(Result.WishlistItems);
      setIsLoading(false);
    });
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   var country;
  //   fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code , {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     })
  //     .then((Response) => {
  //       if (Response.ok) {
  //       return Response.json();
  //       }else{
  //       return ""
  //       }
  //     })
  //     .then((Result) => {
        
  //   country= Result.CountryId
   
  // })
 
};
const HandleSubmit = async (ProductId,ProductVariationId) => {
  if(isLogged){
    // var CartId = Util.decrypt(localStorage.getItem("Cart"));
    const CartId = Cookies.get("Cart");
    const token = Cookies.get("CustomerToken");
    let Response = await fetch(Global.API_URL + "Cart/AddItemToCart",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      
      body: JSON.stringify({
        CartId: CartId,
        ProductId: ProductId,
        VariationId: ProductVariationId,
        Quantity: 1
      })
    }
    ).then((response) => {
      if(response.ok){
        toast.success("Item added to cart");
        return response.json();
      } else {
        return Response.text().then((text) => {
          throw new Error(text);
        });
      }
    }).catch((error) => {
      toast.error("Could Not Add");
    })
  } else {
    navigate('/UserAuthentication');
  }
}
  function openSortList() {
    const box = document.getElementById("SortBox");
    box.classList.add("sortListBoxShow");
  }

  function closeSortList() {
    const box = document.getElementById("SortBox");
    box.classList.remove("sortListBoxShow");
  }

  const HandleDelete = async (ProductId) => {
    // var WishlistId = Util.decrypt(localStorage.getItem("WishList"))
    const WishlistId = Cookies.get("WishList");
    const token = Cookies.get("CustomerToken");
    let Response = await fetch(Global.API_URL + "Wishlist/RemoveItemFromWishlist" +"/"+ ProductId +"/"+ WishlistId ,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    }
    ).then((response) => {
      if(response.ok){
        getWishListItems();
        toast.success("Item Removed");
        return response.json();
      } else {
        return Response.text().then((text) => {
          throw new Error(text);
        });
      }
    }).catch((error) => {
      toast.error("Could Not Delete");
    })
  }
  useEffect(() => {
    if (Cookies.get("CustomerToken") === undefined) {
      navigate("/UserAuthentication")
     } else {
      props.setHeaderTitle("WishList")
      props.setFooterActive(4);
      getWishListItems();
     }
     if (Cookies.get("CustomerToken") === undefined) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  
  
  }, [])

  return (
    <>
      <div className="container wishlistSection ">
        <div className="row  d-none d-md-block">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Wishlist</li>
            </ul>
          </div>
        </div>

        {
          isLoading ? (
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <div>
               {/* <div className="row  d-none d-md-block">
                  <div className="col-12 col-md-12">
                    <h1>Wishlist</h1>
                  </div>
                  <div className="row topFilterLine d-none d-md-flex">
                      <div className="col-3">
                        <label className="filterTitle">Filters</label>
                      </div>
                      <div className="col-5">
                        <span className="noProducts">3 Products</span>
                        <span className="sortBy">
                          <strong>Sort: </strong>
                          <select>
                            <option>Relevence</option>
                          </select>
                        </span>
                      </div>
                      <div className="col-4 listBy text-end">
                        <FaList />
                        <BsGridFill />
                        <BsGrid3X3GapFill />
                      </div>
                    </div>
                </div> */}
                {/*<div className="row mobileFilters d-md-none mb-3">
                  <div className="col-8">
                    <span className="Filtername" onClick={openSortList}><span className="red">Sort: </span>by Relevance</span>
                  </div>
                  <div className="col-4 listByMobile text-end">
                    <FaList />
                    <span className="red"><BsGridFill /></span>
                    <BsGrid3X3GapFill />
                  </div>
              </div>*/}
                <div className="row">
                  
                  {
                    WishListItems.map((item,index) => {
                      return (
                        <div key={index} className="col-6 col-md-3">
                          <div className="item">
                            <button className="btnWishList"
                            onClick={() => HandleDelete(item.ProductId)}
                            >
                              <img src={activeWishListIcon} alt="In WishList" ></img>
                            </button>
                            {!item.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                            <img src={Global.PHOTO_URL + item.Image} alt="Product" />
                            <div className="content">
                              <h5>{item.ProductName}</h5>
                              <p className="sub" dangerouslySetInnerHTML={{
                                  __html: item.Description,
                                }}></p>
                              <div className="price">
                                {
                                    item.SalePrice != 0 ? (
                                      <p>
                                      <span className="sale">${item.SalePrice}</span>
                                      </p>
                                    ) : (
                                      <p>
                                        <span>
                                          ${item.RegularPrice}
                                        </span>
                                      </p>
                                    )
                                  }
                                  {item.InStock ?     <button
                                onClick={() => HandleSubmit(item.ProductId, item.DefaultProductVariationId)}
                                >
                                  <img src={addcart} alt="Add to Cart"></img>
                                </button>
    :<div class="wishList-Align-Text">Out of Stock</div>
  
  }
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                 
        </div>

            </div>
          )
        }
       
        {/* <div className="row topFilterLine d-none d-md-flex">
          <div className="col-3">
            <label className="filterTitle">Filters</label>
          </div>
          <div className="col-5">
            <span className="noProducts">3 Products</span>
            <span className="sortBy">
              <strong>Sort: </strong>
              <select>
                <option>Relevence</option>
              </select>
            </span>
          </div>
          <div className="col-4 listBy text-end">
            <FaList />
            <BsGridFill />
            <BsGrid3X3GapFill />
          </div>
        </div> */}
        {/* <div className="row mobileFilters d-md-none mb-3">
          <div className="col-8">
            <span className="Filtername" onClick={openSortList}><span className="red">Sort: </span>by Relevance</span>
          </div>
          <div className="col-4 listByMobile text-end">
            <FaList />
            <span className="red"><BsGridFill /></span>
            <BsGrid3X3GapFill />
          </div>
        </div> */}
        
        <div id="SortBox" className="sortListBox">
          <div className="row">
            <div className="col-6">
              <label className="sortTitle">Sort:</label>
            </div>
            <div className="col-6 text-end">
              <button onClick={closeSortList} type="button" className="closeSort"><FiX /></button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul>
                <li>Revelence</li>
                <li>Price: Low to High</li>
                <li>Price: High to Low</li>
                <li>Availability</li>
                <li>Rating</li>
              </ul>
            </div>
          </div>
        </div>
      </div></>
  )
}
export default WishList