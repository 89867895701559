import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Util from "../../Classes/Util";
import { Global } from "../../Variables/Global";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const AddressBook = (props) => {
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [Notes, setNotes] = useState("");
  const [key, setKey] = useState("home");
  const [addressBook, setAddressBook] = useState("");
  const [cityId, setCityId] = useState("");
  const [addBool, setAddBool] = useState(false);

  const navigate = useNavigate();

  const getAddressBookDetailsHome = () => {
    // var CustomerId = Util.decrypt(localStorage.getItem("Number"));
    const CustomerId = Cookies.get("Number");
    const token = Cookies.get("CustomerToken");

    setAddress1("");
    setAddress2("");
    setEmail("");
    setLastName("");
    setFirstName("");
    setPhoneNumber("");
    setCity("");
    setAddressBook("");
    fetch(
      Global.API_URL +
        "AddressBook/GetOrderAddressBookByAddressType/" +
        key +
        "/" +
        CustomerId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.statusText === "Unauthorized") {
          toast.error("you dont have An Address yet");
        }
      })
      .then((Result) => {
        setAddressBook(Result);
        setAddBool(true);
        setAddress1(Result.Address1);
        setAddress2(Result.Address2);
        setFirstName(Result.FirstName);
        setLastName(Result.LastName);
        setPhoneNumber(Result.PhoneNumber);
        setEmail(Result.Email);
        setNotes(Result.Notes);
        fetch(Global.API_URL + "Country/GetCitiyNameById/" + Result?.CityId, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((Result) => {
            setCity(Result[0].CityName);
            setCityId(Result[0].CityId);
          });
      })
      .catch((error) => {
        if (
          error.message.replaceAll('"', "") ===
          "You dont have an address book for Work"
        ) {
          // toast.error(error.message);
        }
      });
  };

  // const getAddressBookDetailsWork = () => {
  //     var CustomerId = Util.decrypt(localStorage.getItem("Number"));
  //     fetch(Global.API_URL + "Order/GetOrderAddressBookByAddressType/" + "work" + "/" + CustomerId, {
  //         method: 'GET',
  //         headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //         },
  //     }).then((response) => {
  //         if (response.ok) {
  //             return response.json();
  //         }
  //     }).then((Result => {
  //         setAddressBookWork(Result);
  //     }))
  // }
  const HandleSubmitAddress = () => {
    // var CustomerId = Util.decrypt(localStorage.getItem("Number"));
    const CustomerId = Cookies.get("Number");
    const token = Cookies.get("CustomerToken");
    fetch(Global.API_URL + "AddressBook/AddAddressBook", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        CustomerId: CustomerId,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        PhoneNumber: PhoneNumber,
        Address1: Address1,
        Address2: Address1,
        CityId: city,
        IsDefault: false,
        IsSamePerson: true,
        IsSameShippingAddress: false,
        CountryCode: "LB",
        PostalCode: "00000",
        State: "Lebanon",
        AddressType: key,
      }),
    }).then((response) => {
      if (response.ok) {
        toast.success("Address Book Added");
        return Response.json();
      } else throw Error("Did Not Receive expected data");
    });
  };
  const HandleUpdateAddress = () => {
    // var CustomerId = Util.decrypt(localStorage.getItem("Number"));
    const CustomerId = Cookies.get("Number");
    const token = Cookies.get("CustomerToken");
    fetch(Global.API_URL + "AddressBook/UpdateAddressBook", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        CustomerId: CustomerId,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        PhoneNumber: PhoneNumber,
        Address1: Address1,
        Address2: Address1,
        CityId: cityId,
        IsDefault: false,
        IsSamePerson: true,
        IsSameShippingAddress: false,
        CountryCode: "LB",
        PostalCode: "00000",
        State: "Lebanon",
        AddressType: key,
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Address Book Updated");
          return Response.json();
        } else throw Error("Did Not Receive expected data");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    getAddressBookDetailsHome();
    // getAddressBookDetailsWork();
  }, [key]);

  return (
    <>
      <Tabs
        activeKey={key}
        id="controlled-tab-example"
        className="mb-3"
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="Home">
          <div className="row">
            <div className="col-12">
              <label className="title">Home Address</label>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="infoTitle">Full Name:</label>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    className="inputBox"
                    value={FirstName}
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="inputBox"
                    value={LastName}
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className='row'>
                                                <div className='col-3'>
                                                    <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mr.</label>
                                                </div>
                                                <div className='col-3'>
                                                    <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                                                </div>
                                            </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="infoTitle">Email</label>
            </div>
            <div className="col-9">
              <input
                type="email"
                className="inputBox"
                value={Email}
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="infoTitle">Phone Number</label>
            </div>
            <div className="col-9">
              <div className="col-12">
                <PhoneInput
                  placeholder="Type here"
                  value={PhoneNumber}
                  className="inputBox"
                  onChange={setPhoneNumber}
                />
              </div>
            </div>
          </div>

          {/* <div className='row'>
                        <div className='col-4 col-md-3'>
                            <label className='infoTitle'>Country:</label>
                        </div>
                        <div className='col-8 col-md-9'>
                            <select className='inputBoxSelect'>
                                <option className='info' selected disabled>-- Choose a Country --</option>
                                <option className='info'>Country</option>
                            </select>
                        </div>
                    </div> */}
          <div className="row">
            <div className="col-4 col-md-3">
              <label className="infoTitle">City:</label>
            </div>
            <div className="col-8 col-md-9">
              <select
                className="inputBoxSelect"
                onChange={(e) => {
                  setCity(e.target.value);
                  setCityId(e.target.value);
                }}
              >
                <option className="info" value={cityId}>
                  {city != null || city != "" ? city : "Choose a city"}
                </option>
                <option className="info" value="16124">
                  North
                </option>
                <option className="info" value="16125">
                  South
                </option>
                <option className="info" value="16126">
                  Beirut
                </option>
                <option className="info" value="16127">
                  Mont-Liban
                </option>
                <option className="info" value="16128">
                  Bekaa
                </option>
                <option className="info" value="16129">
                  Nabatieh
                </option>
              </select>
            </div>
            <div className="row">
              <div className="col-4 col-md-3">
                <label className="infoTitle">Address1:</label>
              </div>
              <div className="col-8 col-md-9">
                <input
                  type="text"
                  value={Address1}
                  className="inputBox"
                  placeholder="Type Here"
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-3">
              <label className="infoTitle">Address2:</label>
            </div>
            <div className="col-8 col-md-9">
              <textarea
                placeholder="Type Address"
                value={Address2}
                className="textareaBox"
                cols="4"
                rows="3"
                onChange={(e) => setAddress2(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              {addressBook != "" && addressBook != null ? (
                <button
                  onClick={() => HandleUpdateAddress()}
                  className="editBtn"
                >
                  Update
                </button>
              ) : (
                <button
                  onClick={() => HandleSubmitAddress()}
                  className="editBtn"
                >
                  Submit
                </button>
              )}
            </div>
            <div className="col-md-4">
              <div className="d-md-none">
                <button className="outBtn">Log- out</button>
              </div>
            </div>
          </div>
          {/* <div className='d-md-none'>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='editBtn'>Submit</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='outBtn'>Log- out</button>
                            </div>
                        </div>
                    </div> */}
        </Tab>
        <Tab eventKey="work" title="Work">
          <div className="row">
            <div className="col-12">
              <label className="title">Work Address</label>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="infoTitle">Full Name:</label>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    className="inputBox"
                    value={FirstName}
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="inputBox"
                    value={LastName}
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className='row'>
                                                <div className='col-3'>
                                                    <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mr.</label>
                                                </div>
                                                <div className='col-3'>
                                                    <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                                                </div>
                                            </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="infoTitle">Email</label>
            </div>
            <div className="col-9">
              <input
                type="email"
                className="inputBox"
                value={Email}
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="infoTitle">Phone Number</label>
            </div>
            <div className="col-9">
              <div className="col-12">
                <PhoneInput
                  placeholder="Type here"
                  className="inputBox"
                  value={PhoneNumber}
                  onChange={setPhoneNumber}
                />
              </div>
            </div>
          </div>

          {/* <div className='row'>
                        <div className='col-4 col-md-3'>
                            <label className='infoTitle'>Country:</label>
                        </div>
                        <div className='col-8 col-md-9'>
                            <select className='inputBoxSelect'>
                                <option className='info' selected disabled>-- Choose a Country --</option>
                                <option className='info'>Country</option>
                            </select>
                        </div>
                    </div> */}
          <div className="row">
            <div className="col-4 col-md-3">
              <label className="infoTitle">City:</label>
            </div>
            <div className="col-8 col-md-9">
              <select
                className="inputBoxSelect"
                onChange={(e) => {
                  setCity(e.target.value);
                  setCityId(e.target.value);
                }}
              >
                <option className="info" value={cityId}>
                  {city != null || city != "" ? city : "Choose a city"}
                </option>
                <option className="info" value="16124">
                  North
                </option>
                <option className="info" value="16125">
                  South
                </option>
                <option className="info" value="16126">
                  Beirut
                </option>
                <option className="info" value="16127">
                  Mont-Liban
                </option>
                <option className="info" value="16128">
                  Bekaa
                </option>
                <option className="info" value="16129">
                  Nabatieh
                </option>
              </select>
            </div>
            <div className="row">
              <div className="col-4 col-md-3">
                <label className="infoTitle">Address1:</label>
              </div>
              <div className="col-8 col-md-9">
                <input
                  type="text"
                  value={Address1}
                  className="inputBox"
                  placeholder="Type Here"
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-3">
              <label className="infoTitle">Address2:</label>
            </div>
            <div className="col-8 col-md-9">
              <textarea
                placeholder="Type Address"
                value={Address2}
                className="textareaBox"
                cols="4"
                rows="3"
                onChange={(e) => setAddress2(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              {addressBook != "" && addressBook != null ? (
                <button
                  onClick={() => HandleUpdateAddress()}
                  className="editBtn"
                >
                  Update
                </button>
              ) : (
                <button
                  onClick={() => HandleSubmitAddress()}
                  className="editBtn"
                >
                  Submit
                </button>
              )}
            </div>
            <div className="col-md-4">
              <div className="d-md-none">
                <button className="outBtn">Log- out</button>
              </div>
            </div>
          </div>

          {/* <div className='d-md-none'>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='editBtn'>Submit</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='outBtn'>Log- out</button>
                            </div>
                        </div>
                    </div> */}
        </Tab>
      </Tabs>
    </>
  );
};
export default AddressBook;
