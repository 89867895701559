
import logo from "../../images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import wishList from "../../images/icons/heart.png";
import cart from "../../images/icons/cart.png";
// import profile from "../../images/icons/user.png";
import search from "../../images/icons/search.png";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { MdOutlineNotifications } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { FiSliders } from "react-icons/fi";
import { HiX } from 'react-icons/hi';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Global } from "../../Variables/Global";
import Accordion from 'react-bootstrap/Accordion';
import { useRef } from "react";
import ShoppingCart from '../../images/icons/cart x1.png'
import profile from '../../images/icons/profile x1.png';
import Search from '../../images/icons/search x1.png';
import WishlistIcon from '../../images/icons/wishlist x1.png';
import Cookies from "js-cookie";


const ToggleMenuStyles = {
  svg: {
    width: "70%",
    height: "70%",
    color: "white",
  },

  div: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 999,
    padding: "1rem",
    width: "80%",
    height: "100vh",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
    backgroundColor: "white",
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
    boxShadow: "0 0 20px rgba(168,168,168, 0.15)",

    "@media screen and (min-width: 900px)": {
      display: "none",
    }
  },

  svg2: {
    width: "35px",
    height: "35px",
    color: "313bac",
    margin: "0.5rem 1rem",
  },

  ul: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },

  li: {
    margin: "1rem",

  },
  subli: {
    margin: "0.5rem",
  },

  a: {
    textDecoration: "none",
    color: "red",
    textTransform: "uppercase",
    fontSize: "1rem",
    transition: "all 0.3s ease-in-out",
    fontWeight: "500",
    "&:hover": {
      color: "313bac",
    }

  },
  ab: {
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: "1rem",
    transition: "all 0.3s ease-in-out",
    fontWeight: "500",
    "&:hover": {
      color: "313bac",
    }

  },
  Suba: {
    textDecoration: "none",
    color: "#6b7688",
    textTransform: "uppercase",
    fontSize: "0.7rem",
    transition: "all 0.3s ease-in-out",
    fontWeight: "500",
    "&:hover": {
      color: "313bac",
    }

  }

};

const Header = (props) => {
  const [isLogged, setIsLogged] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [categories, setCategories] = useState([]);
  const [suggestedProduct, setSuggestedProduct] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [isSuggOpen, setIsSuggOpen] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  let LIMIT = 15;
  const getCategories = () => {
    fetch(Global.API_URL + "Category/GetCategories", {
      method: 'Get',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((Result) => {
      setCategories(Result);
    })
  }

  



  const getSuggestedProducts = useMemo(() => {

    setIsSuggOpen(true);
    const offset = new Date().getTimezoneOffset();
    fetch(Global.API_URL + "Products/GetSearchedProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        Term: searchText,
        count: LIMIT,
        offset: offset,
        attributes: [],
        categories: [],
        prices: [],
      }),
    })
      .then((Response) => {
        if (Response.ok) return Response.json();
        else throw Error("Did Not Receive expected data");
      })
      .then((Result) => {
        setSuggestedProduct(Result.finalList);
        setIsSuggOpen(false);
      })
  }, [searchText]);


  const handleSubmit = (e) => {
    
    e.preventDefault();
    navigate(`/SearchedProduct/${searchText}`);
    // if(searchText.length > 0){
    //   navigate(`/SearchedProduct/${searchText}`);
    // } else {

    //   navigate(`/SearchedProduct`);
    // }
    
  }

  const onClickOutside = () => {
    setIsSuggOpen(false);

  }

  const clickOutsideHandler  = useMemo(() => {
    const handleClickOutside = (event) => {
      const isFormSubmit = event.target.closest('form');
      if (!ref?.current?.contains(event.target)) {
        setsearchText("");
      }
    };
    document.addEventListener("click", handleClickOutside);


    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  },[ref, setsearchText])

  useEffect(() => {
    if (Cookies.get("CustomerToken") === undefined) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
    getCategories();
   
    
  }, [props]);
  // onClickOutside
  // onClickOutside
  return (
    <header>
      <div className="container d-none d-md-block desktopHeader">
        <div className="row">
          <div className="col-md-2 col-lg-2">
            <Link to={"/"}>
              <img src={logo} alt="Play Mega Store Logo" />
            </Link>
          </div>
          <div className="col-md-6 col-lg-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <ul className="pt-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', listStyle: 'none' }}>
                <li style={ToggleMenuStyles.li}>
                  {/* <Link to="/Category/Deal Bundle/19"> */}
                  <a style={ToggleMenuStyles.ab} href="/Category/Deal Bundle/19">Deal Bundle</a>
                  {/* </Link> */}
                </li>
                <li style={ToggleMenuStyles.li}>
                  {/* <Link to="/Category/On sale/21"> */}
                  <a style={ToggleMenuStyles.ab} href="/Category/On sale/21">On sale</a>
                  {/* </Link> */}
                </li>
              </ul>
            </div>
            {/* <form onSubmit={handleSubmittest}>
            <input type="text" onChange={(e) => {setsearchText(e.target.value)}} />

            </form> */}
            {/* onSubmit={handleSubmit} */}
            <form  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="position-relative ms-2">
                <input className="inputBox ms-2" style={{ border: "2px solid red", borderRadius: "7px" }} placeholder=" Search..." type="text" value={searchText} onChange={(e) => {setsearchText(e.target.value)}} />
                
                {
                  searchText.length > 2 && suggestedProduct.length > 0 ? (
                    
                    <div ref={ref} className="suggested-content">
                      {/* <HiX onClick={() => setIsSuggOpen(false)} /> */}
                      {
                        suggestedProduct.slice(1, 4).map((product,index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className='row suggested-content-Main'>
                                <div className='col-4'>
                                  <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                    <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} className="SuggestImg" />
                                  </Link>
                                </div>
                                <div className='col-8'>
                                  <p className='itemName'>{product.ProductName}</p>
                                  {
                                    product.DefaultVariation.SalePrice == 0 ? (<span className='itemPrice float-end'>${product.DefaultVariation.RegularPrice}</span>) : (
                                      <span className='itemPrice float-end'>
                                        ${product.DefaultVariation.SalePrice}
                                      </span>
                                    )

                                  }
                                </div>
                                <hr />
                              </div>

                            </React.Fragment>
                          );
                        })
                      }
                      {/* {
                        !suggProdLoading && (
                          <div className="viewMore-Button">
                            <h5 onClick={navigate(`/SearchedProduct/${searchText}`)}>View More</h5>
                          </div>
                        )
                      } */}
                      <div className="viewMore-Button">
                        <h5 onClick={handleSubmit}>View More</h5>
                      </div>
                    </div>
                  ) : (null)
                }
              </div>

              <div className="ms-2">

                {/* <Link to={`/SearchedProduct/${searchText}`}> */}
                <button type="submit" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={handleSubmit}>

                  {
                    isSuggOpen ? (
                      <div className="spinner-search">
                        <div className="search-spinner"></div>
                      </div>
                    ) : (
                      <img src={Search} alt="search icon" />
                    )}
                </button>


              </div>
            </form>
          </div>
          <div className="col-md-4 col-lg-4 text-lg-end">
            <ul className="socialMedia">
              <li>
                <Link to={isLogged ? "/WishList" : "/UserAuthentication"}>
                  {/* <img src={wishList} alt="Wish List" /> */}
                  <img src={WishlistIcon} alt="Wish List" />
                </Link>
              </li>
              <li>
                {/* <Link to={"/Cart"}> */}
                <Link to={isLogged ? "/Cart" : "/UserAuthentication"}>
                  {/* <img src={cart} alt="Cart" /> */}
                      <img src={ShoppingCart} alt="cart" />
                </Link>
              </li>
              <li>
                <Link to={isLogged ? "/Profile" : "/UserAuthentication"}>
                  <img src={profile} alt="Profile" />
                </Link>
              </li>
              {/* <li>
                  <a href="#">
                    <img src={search} alt="Search" />
                  </a>
              </li> */}
            </ul>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <Navbar expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/Category/Computers/1">Computers</Nav.Link>
                    <Nav.Link href="/Category/Phones-Tables/2">Phones & Tablets</Nav.Link>
                    <Nav.Link href="/Category/Games/3">Games & Consules</Nav.Link>
                    <Nav.Link href="/Category/Gadgets/4">Gadgets</Nav.Link>
                    <Nav.Link href="/Category/Accessories/5">Accessories</Nav.Link>
                    <Nav.Link href="/Services">Services</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar> */}

            {/*categories.map((cat) => {
                return (
                  <div className="">
                    <Dropdown style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Dropdown.Toggle variant=" primary" id="dropdown-basic" style={{ textTransform: 'uppercase'}}>
                        {cat.CategoryName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          cat.SubCategory.map((subcat) => {
                            return (
                              <Dropdown.Item style={{ textTransform: 'uppercase'}} href={`/Category/${subcat.CategoryName}/${subcat.CategoryId}`}>{subcat.CategoryName}</Dropdown.Item>
                            )
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
              })*/}
            {

              categories.map((cat,index) => {
                return (
                  <div className="dropdown" key={index}>
                    <a className="dropbtn" href={`/Category/${cat.CategoryName}/${cat.CategoryId}`}>{cat.CategoryName}</a>
                    <div className="dropdown-content">
                      {
                        cat.SubCategory.map((subcat,index) => {
                          return (
                            <a key={index} href={`/Category/${subcat.CategoryName}/${subcat.CategoryId}`}>{subcat.CategoryName}</a>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })

            }





            {/* <div>
              <Dropdown>
                <Dropdown.Toggle variant=" primary" id="dropdown-basic">
                  SHOP
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Category/Computers/1">Computers</Dropdown.Item>
                  <Dropdown.Item href="/Category/Phones-Tables/2">Phones & Tablets</Dropdown.Item>
                  <Dropdown.Item href="/Category/Games/3">Games & Consules</Dropdown.Item>
                  <Dropdown.Item href="/Category/Gadgets/4">Gadgets</Dropdown.Item>
                  <Dropdown.Item href="/Category/Accessories/5">Accessories</Dropdown.Item>
                  <Dropdown.Item href="/Services">Services</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            {/* <div className="position-relative ms-2">
              <input className="inputBox ms-2" style={{ borderRadius: '7px' }} placeholder="Search Here" type="text" onChange={(e) => setsearchText(e.target.value)} />
            </div>
            <div className="ms-2">
              <Link to={`/SearchedProduct/${searchText}`}>
                <BsSearch style={{}} className="search" />

              </Link>
            </div> */}

          </div>
        </div>

      </div>
      <div className="conatiner d-md-none mobileHeader">
        <div className="row">
          <div className="col-9">
            <label className="title">{props.headerTitle}</label>
          </div>
          <div className="col-3 text-end m-auto position-relative">
            <MdOutlineNotifications className="bell" />
            <div className="dot">.</div>
          </div>
        </div>
        <div className="row">
          <div className="col-8 position-relative">
            <BsSearch className="search" />
            <input className="inputBox" placeholder="Search Here" type="text" onChange={(e) => setsearchText(e.target.value)} />
            {/* <button>Search</button> */}
          </div>
          <div className="col-2 pt-3">
            {/* <Link to={`/SearchedProduct/${searchText}`}>
              <a style={{ backgroundColor: "#c90016", color: "white", borderRadius: 10, borderColor: "white", padding: 5 }}>
                Search
              </a>
            </Link> */}
            <Link to={`/SearchedProduct/${searchText}`}>
              <BsSearch style={{}} />

            </Link>
          </div>
          <div className="col-2 ps-0">
            <button onClick={() => setToggle(true)} className="searchBtn"><FiSliders style={ToggleMenuStyles.svg} /></button>
            {
              toggle && (
                <div style={ToggleMenuStyles.div}>
                  <HiX onClick={() => setToggle(false)} style={ToggleMenuStyles.svg2} />
                  {/* <ul style={ToggleMenuStyles.ul}>
                    <li style={ToggleMenuStyles.li}>
                      <Link to="/Category/Computers/1">
                        <a style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Computers</a>
                      </Link>
                    </li>
                    <li style={ToggleMenuStyles.li}>
                      <Link to="/Category/Computers/2">
                        <a style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Phones & Tablets</a>
                      </Link>
                    </li>
                    <li style={ToggleMenuStyles.li}>
                      <Link to="/Category/Computers/3">
                        <a style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Games & Consules</a>
                      </Link>
                    </li>
                    <li style={ToggleMenuStyles.li}>
                      <Link to="/Category/Computers/4">
                        <a style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Gadgets</a>
                      </Link>
                    </li>
                    <li style={ToggleMenuStyles.li}>
                      <Link to="/Category/Computers/5">
                        <a style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Accessories</a>
                      </Link>
                    </li>
                    <li style={ToggleMenuStyles.li}>
                      <Link to="/services">
                        <a style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Services</a>
                      </Link>
                    </li>
                  </ul> */}
                  <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
                    <ul style={ToggleMenuStyles.ul}>
                      <li style={ToggleMenuStyles.li}>
                        <Link to="/Category/Deal Bundle/19">
                          <span style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Deal Bundle</span>
                        </Link>
                      </li>
                      <li style={ToggleMenuStyles.li}>
                        <Link to="/Category/Featured/20">
                          <span style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Featured</span>
                        </Link>
                      </li>
                      <li style={ToggleMenuStyles.li}>
                        <Link to="/Category/On sale/21">
                          <span style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>On sale</span>
                        </Link>
                      </li>
                      <li style={ToggleMenuStyles.li}>
                        <Link to="/Category/Top Rated/22">
                          <span style={ToggleMenuStyles.a} onClick={() => setToggle(false)}>Top Rated</span>
                        </Link>
                      </li>
                    </ul>
                    {
                      categories.map((cat,index) => {
                        return (
                          <ul key={index} style={ToggleMenuStyles.ul}>
                            <li style={ToggleMenuStyles.li}>
                              {/* <Link to={`/Category/${cat.CategoryName}/${cat.CategoryId}`}> */}
                              <a style={ToggleMenuStyles.a} href={`/Category/${cat.CategoryName}/${cat.CategoryId}`} onClick={() => setToggle(false)}>{cat.CategoryName}</a>
                              {/* </Link> */}
                              {
                                cat.SubCategory.map((subcat,index) => {
                                  return (

                                    <li key={index} style={ToggleMenuStyles.subli}>
                                      {/* <Link to={`/Category/${subcat.CategoryName}/${subcat.CategoryId}`}> */}
                                      <a style={ToggleMenuStyles.Suba} href={`/Category/${subcat.CategoryName}/${subcat.CategoryId}`} onClick={() => setToggle(false)}>{subcat.CategoryName}</a>
                                      {/* </Link> */}
                                    </li>
                                  );
                                })
                              }
                            </li>
                          </ul>
                        )
                      })
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </header >
  )
}

export default Header