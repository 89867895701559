import React, { useEffect } from "react";
import product from "../../../images/products/product9.png";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";
import Util from "../../../Classes/Util";
import { Global } from "../../../Variables/Global";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
import ThankYouPopUp from './ThankYouPopUp';
import { Button } from 'react-bootstrap';

const Cart = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [AnItemNotInStock, setAnItemNotInStock] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get("CustomerToken");

  const [showModal, setShowModal] = useState(false); 

  
  const handleModal = () => {
    setShowModal(!showModal);
    // Refresh the page when modal is closed
    navigate('/Cart');
    window.location.reload();
  };


  const getCartItems = async () => {
    setIsLoading(true);
    var groupId;
    if (Cookies.get("WishList") != undefined) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const offset = new Date().getTimezoneOffset();
    // var CartId = Util.decrypt(localStorage.getItem("Cart"))
    var CartId = Cookies.get("Cart");
    const token = Cookies.get("CustomerToken");
    fetch(
      Global.API_URL +
      "Cart/GetCartItems/" +
      CartId +
      "/" +
      offset +
      "/" +
      125 +
      "/" +
      groupId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((Result) => {
        setCartCount(Result.Count);
        setCartItems(Result.CartItems);
        setAnItemNotInStock(false)
        Result.CartItems.map((item) => {

          if(!item.InStock) 
          {
            setAnItemNotInStock(true)
          }
        });
        setIsLoading(false);
      });
    //   const res = await axios.get("https://geolocation-db.com/json/");
    //   var country;
    //   fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code , {
    //     method: "GET",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     })
    //     .then((Response) => {
    //       if (Response.ok) {
    //       return Response.json();
    //       }else{
    //       return ""
    //       }
    //     })
    //     .then((Result) => {
    //   country= Result.CountryId

    // })
  };
  const HandleDelete = async (ProductVariationId) => {
    // var CartId = Util.decrypt(localStorage.getItem("Cart"));
    const CartId = Cookies.get("Cart");
    const token = Cookies.get("CustomerToken");
    let Response = await fetch(
      Global.API_URL +
      "Cart/RemoveItemFromCart" +
      "/" +
      ProductVariationId +
      "/" +
      CartId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.API_URL,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          getCartItems();
          toast.success("Item Removed");
          
          return response.json();
        } else {
          return Response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .catch((error) => {
        toast.error("Could Not Delete");
      });
  };


  useEffect(() => {
    //  setShowModal(true);
    //  navigate("/ThankYouPopUp")
    const searchParams = new URLSearchParams(location.search);
    const resultIndicator = searchParams.get('resultIndicator');
    const orderId = searchParams.get('OrderId');

    if (orderId) {
      GetPopUpDetails(orderId);
    }

    if (resultIndicator) {


      fetch(
        Global.API_URL +
        "Order/CheckPayment?" +
        new URLSearchParams({
          ResultIndicator: resultIndicator,
        }),
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      ).then((response) => {

        if (response.ok) {
          toast.success("Payment Successfuly Compeleted");

          return response.json();

        }
        else {
          toast.error("Payment was not Compeleted");
        }
      }).then((response) => {

        navigate(`/Cart?OrderId=${response.OrderId}`);

      });
    }
  }, [location.search]);


  useEffect(() => {


    if (Cookies.get("CustomerToken") === undefined) {
      navigate("/UserAuthentication");
    } else {
      props.setHeaderTitle("Cart");
      props.setFooterActive(3);
      getCartItems();
    }
  }, []);

  function GetPopUpDetails(OrderId) {

    const id = Cookies.get("Number");
    fetch(
      Global.API_URL +
      "Order/GetThankYouPopUpDetails?" +
      new URLSearchParams({
        OrderId: OrderId,
        CustomerId: id
      }),
      {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }
    ).then((response) => {

      if (response.ok) {

        return response.json();

      }

    }).then((response) => {



      if (response != null) {
        setModalData(response);
        setShowModal(true);

      }
    });
  }

  function handleInputChange(ProductId, ProductVariationId, value) {
    const CartId = Cookies.get("Cart");
    const token = Cookies.get("CustomerToken");


    const updateData = [{
      ProductId: ProductId,
      VariationId: ProductVariationId,
      Quantity: value
    }];

    return fetch(Global.API_URL + "Cart/UpdateCartItemsQuantity/" + CartId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateData),
    })
      .then((response) => response.json())
      .then((data) => {


        getCartItems();
      })
      .catch((error) => {
        console.error('Error updating cart items quantity:', error);
        // Handle errors here
      });
  }



  return (
    <>


      <div>

        <ThankYouPopUp show={showModal} handleClose={handleModal} modalData={modalData} />
      </div>



      <div className="container Cart">
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Cart</li>
            </ul>
          </div>
        </div>



        {isLoading ? (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md-12">
              <h1>Cart({cartCount})</h1>
            </div>
            <div>
              <div className="row topMargin">
                <div className="col-12 col-lg-9">
                  {cartItems.map((item) => {
                  
                    return (
                      <div
                        key={item.ProductVariationId}
                        className="infoBox position-relative shadowBoxMobile"
                      >
                        <div className="row ">
                          <div className="col-12 col-md-3">
                            <img
                              src={Global.PHOTO_URL + item.Image}
                              className="cartImg"
                              
                            />
                            <div>
                      {!item.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                      </div>
                          </div>
                          <div className="col-9 m-auto ">
                            <div className="row">
                              <div className="col-8">
                                <label className="itemName">
                                  {item.ProductName}
                                </label>
                                <br />
                                <p className="itemDesc" dangerouslySetInnerHTML={{
                                  __html: item.Description
                                }}></p>

                                {item.SalePrice != 0 ? (
                                  <p>
                                    <span className="off">
                                      -
                                      {((item.RegularPrice - item.SalePrice) *
                                        100) /
                                        item.RegularPrice}
                                      %
                                    </span>
                                    <span className="old">
                                      Was:{" "}
                                      <strike> ${item.RegularPrice}</strike>
                                    </span>
                                    <span className="sale">
                                      ${item.SalePrice}
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>${item.RegularPrice}</span>
                                  </p>
                                )}
                              </div>
                              <div className="col-4 text-end mt-25">
                                <span className="quantity">Qty: </span>
                                <input
                                  className="quantityBox"
                                  type="number"
                                  min="1"
                                  value={item.Quantity}
                                  onChange={e => {
                                    handleInputChange(item.ProductId, item.ProductVariationId, parseInt(e.target.value));
                                  }}
                                />
                                <br />
                                <span className="sale">
                                  ${item.RegularPrice * item.Quantity}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <button
                                  className="deleteBtn"
                                  onClick={() =>
                                    HandleDelete(item.ProductVariationId)
                                  }
                                >
                                  <FaTrashAlt />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="col-12 col-lg-3">
                  {/* <div className="infoBox ">
          <div className='row'>
            <div className='col-8'>
              <label className='sideTitlep'>1 iPhone X Repair</label>
            </div>
            <div className='col-4 text-end'>
              <label className='price'>$ 60.00</label>
            </div>
          </div>
          <div className='row'>
            <div className='col-8'>
              <label className='sideTitlep'>Delivery</label>
            </div>
            <div className='col-4 text-end'>
              <label className='price'>$ 2.00</label>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-8'>
              <label className='sideTitlep red'>Total</label>
            </div>
            <div className='col-4 text-end'>
              <label className='price'>$ 62.00</label>
            </div>
          </div>
        </div> */}
                  <div className="row">
                    <div className="col-12">
                      <Link
                        to= {(cartCount > 0) && AnItemNotInStock ? "" :"/Checkout"}
                        state={{ cartItems: cartItems, cartCount: cartCount }}
                        onClick={()=> AnItemNotInStock? toast.error("Remove Out Of Stock Item From Cart"):""}
                      >
                        <button
                     
                          type="button"
                          className={AnItemNotInStock ? "proceedBtn cartBtn disabled" :"proceedBtn cartBtn"}
   
                        >
                          proceed to checkout
                        </button>
                       
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Link to="/" type="button" className="continueBtn">
                        continue Shopping
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Cart;
