import React, { useState } from 'react'
import { Global } from '../../../Variables/Global';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Util from '../../../Classes/Util'; 
const ResetPassword = (props) => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState("");
    const [old, setOld] = useState("");

    
    function isValid(){
        if(password==="" || confirmPassword === ""){
            toast.error("Please fill required fields")
          }else if(password!==confirmPassword){
            toast.error("New Password Must match the confirm Password")
          } else{
            var strongPasswordRegex = new RegExp(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
            );
            var validpassword = strongPasswordRegex.test(password);
            if(!validpassword){
              toast.error("Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and Symbols")
              return false;
            }else{
              return true;
            }
          }
    }

      const MoveCart = (Id) => {
    var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
    fetch(Global.API_URL + "Cart/MergeItems/" + Guest + "/" + Id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        Email: email,
        Password: password,
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
        return Response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((Result) => {
        toast.success("Welcome Back");
        window.location.href = "/Profile";
        sessionStorage.clear();
      })
      .catch((error) => {
        if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
          navigate("/Email-Confirmation")
        }
        toast.error(error.message.replaceAll('"', ""));
      });
  };
  const MoveWishList = (Id) => {
    var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
    fetch(Global.API_URL + "Wishlist/MergeItems/" + Guest + "/" + Id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        Email: email,
        Password: password,
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
        return Response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((Result) => {
        MoveCart(Id);
      })
      .catch((error) => {
        if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
          navigate("/Email-Confirmation")
        }
        toast.error(error.message.replaceAll('"', ""));
      });
  };

    function handleSubmit(e){

        if (isValid){     
            const URL = Global.API_URL + "Customer/CustomerResetPassword";
            fetch(URL, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": Global.API_URL,
              },
              body: JSON.stringify({
                email: email,
                oldPassword: old,
                newPassword: password,
              }),
            }).then((response) => {
              if (response.ok) {
                fetch(Global.API_URL + "Customer/CustomerLogin", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": Global.API_URL,
                  },
                  body: JSON.stringify({
                    Email: email,
                    Password: password,
                    registrationTypeId: 1,
                    firstName: "",
                    lastName: "",
                    profilePicture: "",
                  }),
                })
                  .then((Response) => {
                    if (Response.ok) {
                      
                      return Response.json();
                    }
                    return Response.text().then((text) => {
                      throw new Error(text);
                    });
                  })
                  .then((Result) => {
                    
                    localStorage.setItem("User", Util.encrypt(email));
                    localStorage.setItem("MPToken", Util.encrypt(Result.CustomerToken));
                    localStorage.setItem("Number", Util.encrypt(Result.CustomerId));
                    localStorage.setItem("WishList", Util.encrypt(Result.WishlistId));
                    localStorage.setItem("Cart", Util.encrypt(Result.CartId));
                    localStorage.setItem(
                      "Subscribe",
                      Util.encrypt(Result.IsSubscribed)
                    );
                    props.setSubscribe(Result.IsSubscribed);
                    //MoveWishList(Result.CustomerId);
                    toast.success("Reset Success Please Login");
                    
                    window.location.href = "/Profile";
                  })
                  .catch((error) => {
                    if (
                      error.message.replaceAll('"', "") === "Please Confirm Your Email"
                    ) {
                      navigate("/Email-Confirmation")
                    }
                    // toast.error(error.message.replaceAll('"', ""));
                  });
               
              } 
            }).catch((error) => {
             
              toast.error(error.message.replaceAll('"', ""));
            });
        }
    }
    return (
        <>
            <div className="container mt-60">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>Profile</li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="authenticationCard">
                            <div className="row topBar">
                                <div className="col-12 text-center">
                                    <button className='active'>Reset Password</button>
                                </div>
                            </div>
                            <div className="row mb-35">
                                <div className="col-12 col-md-2"><label>Email</label></div>
                                <div className="col-12 col-md-10">
                                    <input type="email" className="form-control" placeholder="Type Here" required onChange={(e) => setEmail(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-35">
                                <div className="col-12 col-md-2"><label>Old Password:</label></div>
                                <div className="col-12 col-md-10">
                                    <input type="password" className="form-control" placeholder="Type Here" required onChange={(e) => setOld(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-35">
                                <div className="col-12 col-md-2"><label>New Password:</label></div>
                                <div className="col-12 col-md-10">
                                    <input type="password" className="form-control" placeholder="Type Here" required onChange={(e) => setPassword(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-35">
                                <div className="col-12 col-md-2"><label>Confirm Password:</label></div>
                                <div className="col-12 col-md-10">
                                    <input type="password" className="form-control" placeholder="Type Here" required onChange={(e) => setConfirmPassword(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-35 submit">
                                <div className="col-12 text-center">
                                    <button onClick={(e) => handleSubmit(e)}>Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword