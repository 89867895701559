import React, { useState, useEffect } from "react";
import { Global } from "../../Variables/Global";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Cookies from "js-cookie";

const OrderDetails = (props) => {
    const [total, setTotal] = useState(0);
    const location = useLocation();
    const [sessionId, setSessionId] = useState("");
    const [orderNumber, setOrderNumber] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const [bankFees, setBankFees] = useState(0);
    const getTotal = () => {
        var totall = 0;


       
        props.modalData.OrderDetails.map((a) => {
            totall = totall + (a.TotalPriceItem * a.Quantity)
        })

        setBankFees(Math.ceil((total * 0.035)));
        // const total = (items.OrderDetails.reduce((total, currentItem) => {







        // }, 0));

        setTotal(totall);
    };



    useEffect(() => {
        getTotal();
    });


    return (
        <>

            <Modal
                show={props.show}
                fullscreen={true}
                onHide={() => props.setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {
                        // );
                        props.modalData.OrderDetails.map((item) => {
                            return (
                                <div key={item.ProductId} className='row mb-50'>
                                    <div className='col-4'>
                                        <img src={Global.PHOTO_URL + item.ProductDetails.ImageUrl} className="CheckoutImg" />
                                    </div>
                                    <div className='col-8'>
                                        <p className='itemName'>{item.ProductName}<span>x{item.Quantity}</span></p>

                                        <span className='itemPrice float-end'>
                                            ${item.OriginalPrice}
                                        </span>

                                    </div>
                                </div>
                            )
                        })
                    }
                      <hr />
            <div className='row'>
                <div className='col-6'>
                    <label className='sideTitlep red'>total

                    </label>
                </div>
                <div className='col-6 text-end'>
                    <label className='price red'>$ {total}</label>
                </div>
            </div> */}
                    <div className="row">
                        <div className='row mb-30'>
                            <div className='col-12'>
                                <label className='sideTitlep'>{props.modalData.OrderDetails.length} {props.modalData.OrderDetails.length > 1 ? "Items" : "Item"}</label>
                            </div>
                        </div>

                        <div className='row mb-50'>
                            {
                                props.modalData.OrderDetails.map((item) => {
                          
                                    return (
                                        <div className="col-md-4 col-lg-4 Profile">
                                            <div className='infoBox mt-0'>
                                                <div className="row">
                                                    {/* <div key={item.ProductId} className='col-md-4 mb-50'> */}
                                                    <div className="col-4">
                                                        <img src={Global.PHOTO_URL + item.ProductDetails.ImageUrl} className="CheckoutImg" />
                                                    </div>
                                                    <div className="col-8 pt-4">
                                                        <p className='itemName'>{item.ProductDetails.ProductName}</p>
                                                        <p><span>Quantity: x{item.Quantity}</span></p>
                                                        <span className='itemPrice float-end'>
                                                            ${item.TotalPriceItem}
                                                            
                                                        </span>

                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep'>Subtotal</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 1,010.00</label>
                                </div>
                            </div> */}
                        {/* <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep'>Shipping</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 2.00</label>
                                </div>
                            </div> */}
                        <hr />


                        {
                            props && props.modalData && props.modalData.PaymentMethod == "Online Payment" ? <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep red'>Bank Fees (3.5%)

                                    </label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price red'>$ {bankFees}</label>
                                </div>
                            </div> : null
                        }


                        <div className='row'>
                            <div className='col-6'>
                                <label className='sideTitlep red'>Total

                                </label>
                            </div>
                            <div className='col-6 text-end'>
                                <label className='price red'>$  { props.modalData.orderTotal}</label>
                            </div>
                        </div>


                        <div className="row">

                        </div>
                        {/* <br></br>
              <div className="col-12 ">
                  <button
                  hidden = {(props.modalData.PaymentMethod == 'Online Payment' && props.modalData.StatusId == 7) ? false : true}
                    onClick={handleCheckout}
                    disabled={buttonDisabled}
                    className="outBtn"
                  >
                    Pay Online
                  </button>
                </div> */}
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
export default OrderDetails;