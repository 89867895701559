import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <div className='d-flex justify-content-center Error'>
            <div className='d-flex flex-column'>
                <h1>Page Not Found</h1>
                <Link to="/">
                    <span className="btn btn-default choose-selection-button">Back to home</span>
                </Link>
            </div>
        </div>
    )
}

export default PageNotFound