import React from 'react';
import classnames from 'classnames';
import { usePagination,DOTS  } from './usePaginations';
import './pagination.css';
import { GrNext, GrPrevious } from 'react-icons/gr';

// import './pagination.scss';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });


  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 && paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if(currentPage < Math.ceil(totalCount / pageSize) ){
        onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if(currentPage > 1 ){
        onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
    //   className={classnames('pagination-container', { [className]: className })}
    className='pagination-container'
    >
       {/* Left navigation arrow */}
      {/* <li
      
        
        
      > */}
        {/* <div className="arrow left" /> */}
        <button className="pagination-item" onClick={onPrevious}><GrPrevious /></button>
        
      {/* </li> */}
      {paginationRange.map(pageNumber => {
         
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS ) {
          return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
        }
		
        // Render our Page Pills
        return (
          <li
          key={pageNumber}
            selected={pageNumber === currentPage}
            className={pageNumber === currentPage ? "pagination-item pagination-pages active-page" : "pagination-pages"}                 
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      {/* <li
        
        
      > */}
        {/* <div className="arrow right" /> */}
        <button className="pagination-item" onClick={onNext}><GrNext /></button>
      {/* </li> */}
    </ul>
  );
};

export default Pagination;