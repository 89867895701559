
import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";

const ThankYouPopUp = (props) => {


  function getBankFee() {
    let totalPrice = 0;
   
    props && props.modalData && props.modalData.OrderDetailsList && props.modalData.OrderDetailsList.forEach((item) => {
      totalPrice += item.ProductVariation.SalePrice != 0 ? item.Quantity * item.ProductVariation.SalePrice : item.Quantity * item.OriginalPrice;
    });

    return (totalPrice * 0.035).toFixed(2);
  }

  function getOrderId() {
    
    let OrderId = 0;
    if (props && props.modalData && props.modalData.OrderDetailsList && props.modalData.OrderDetailsList.length > 0) {
      OrderId = props.modalData.OrderDetailsList[0].OrderId;
    }


    return OrderId;
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props && props.modalData && props.modalData.CustomerName && props.modalData.CustomerName}</Modal.Title>


      </Modal.Header>
      <Modal.Body>
        <h4 className="mt-8 theme-color-custom mb-6">Thanks for your order</h4>

        <span className="theme-color-custom">Payment Summary</span>
        <div className="mb-3">
          <hr className="new1-custom" />

        </div>
        <h5>Order# : {getOrderId()}  </h5>
        {
          props && props.modalData && props.modalData.OrderDetailsList && props.modalData.OrderDetailsList.map((item) => {
            return (
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">{item.ProductVariation.Sku} (Qty: {item.Quantity})</span>
                <span className="text-muted">{item.ProductVariation.SalePrice != 0 ? item.Quantity * item.ProductVariation.SalePrice : item.Quantity * item.OriginalPrice}</span>
              </div>
            )
          })
        }
   <div className="mt-3">
          <hr className="new1-custom" />

        </div>

        {
          props && props.modalData && props.modalData.Order.PaymentMethodId == 1 ?   <div className="d-flex justify-content-between mt-3">
          <span className="font-weight-bold">Bank Fees (3.5%)</span>
          <span className="font-weight-bold theme-color-custom"> {Math.ceil(getBankFee())}  </span>
        </div> : null
      
}
        <div className="d-flex justify-content-between">
          <span className="font-weight-bold">Total</span>
          <span className="font-weight-bold theme-color-custom"> {props && props.modalData && props.modalData.Order && props.modalData.Order.OrderTotal}</span>
        </div>

      </Modal.Body>
    </Modal>
  );
}

export default ThankYouPopUp;
