import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { toast } from 'react-toastify';
import { Global } from "../../../Variables/Global";
import Util from "../../../Classes/Util";
// import Cookies from "universal-cookie";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const UserAuthentication = (props) => {
  const [login, setLogin] = useState(true);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [phonenumber, setPhonenumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  // const cookies = new Cookies();
  var emailregx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  function isValid() {
    if (login) {
      //Login
      if (email === "" || password === "") {
        toast.error("please fill required fields");
        return false;
      } else if (!emailregx.test(email)){
        toast.error("Bad email format");
        return false;
      } else {
        return true;
      }
    } else {
      //Registration
      if (
        email === "" ||
        password === "" ||
        firstName === "" ||
        password === "" ||
        confirmPassword === "" 
      ) {
        toast.error("please fill required fields");
        return false;
      } else if(!emailregx.test(email)){
        toast.error("Bad email format");
      } else if (!strongRegex.test(password)){
        toast.error("Password must include  8 Characters Minimum,1 capital letter, 1 number. ");
        return false;
      } else {
        if (password !== confirmPassword) {
          toast.error("passwords do not match1");
          return false;
        } 
        else{
          return true
        }
      }
      
    }
  }

  const MoveCart = (Id) => {
    var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
    fetch(Global.API_URL + "Cart/MergeItems/" + Guest + "/" + Id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        Email: email,
        Password: password,
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
        return Response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((Result) => {
        toast.success("Welcome Back");
        window.location.href = "/Profile";
        sessionStorage.clear();
      })
      .catch((error) => {
        if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
          navigate("/Email-Confirmation")
        }
        toast.error(error.message.replaceAll('"', ""));
      });
  };
  
  const MoveWishList = (Id) => {
    var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
    fetch(Global.API_URL + "Wishlist/MergeItems/" + Guest + "/" + Id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        Email: email,
        Password: password,
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
        return Response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((Result) => {
        MoveCart(Id);
      })
      .catch((error) => {
        if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
          navigate("/Email-Confirmation")
        }
        toast.error(error.message.replaceAll('"', ""));
      });
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (isValid()) {
      if (login) {
        fetch(Global.API_URL + "Customer/CustomerLogin", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
          body: JSON.stringify({
            Email: email,
            Password: password,
           
          }),
        })
          .then((Response) => {
            if (Response.ok) {
              return Response.json();
            }
            return Response.text().then((text) => {
              throw new Error(text);
            });
          })
          .then((Result) => {
            // localStorage.setItem("User", Util.encrypt(email));
            // localStorage.setItem("PMToken", Util.encrypt(Result.CustomerToken));
            // localStorage.setItem("Number", Util.encrypt(Result.CustomerId));
            // // toast.success("Welcome Back");
            // // window.location.href="/Profile";
            // // sessionStorage.clear();
            // localStorage.setItem("WishList", Util.encrypt(Result.WishlistId));
            // localStorage.setItem("Cart", Util.encrypt(Result.CartId));
            // localStorage.setItem(
            //   "Subscribe",
            //   Util.encrypt(Result.IsSubscribed)
            // );
            props.setSubscribe(Result.IsSubscribed);
            Cookies.set('User', email, {
              expires: 5
            });
            Cookies.set('WishList', Result.WishlistId, {
              expires: 5
            });
            Cookies.set('Cart', Result.CartId, {
              expires: 5
            });
            Cookies.set('Subscribe', Result.IsSubscribed, {
              expires: 5
            });
            Cookies.set('Number', Result.CustomerId, {
              expires: 5
            });
            Cookies.set('CustomerToken', Result.Token, {
              expires: 5
            });
            
            // MoveWishList(Result.CustomerId);
            window.location.href = "/Profile";
          })
          .catch((error) => {
            if (
              error.message.replaceAll('"', "") === "Please Confirm Your Email"
              
            ) {
              localStorage.setItem("User", Util.encrypt(email));
              
              navigate("/Email-Confirmation")
            }else if(error.message.replaceAll('"', "") === "Please Reset Your Password"){
              navigate("/Reset-Password")
            } 
            // else if(error.message.replaceAll('"', "") === "Your code is not expired yet check your email"){
            //   navigate("/Email-Confirmation");
            //   toast.error("Your code is not expired yet check your email");
            // } else if(error.message.replaceAll('"', "") === "Your confirmation code has been sent !, please check your email for the new one"){
            //   navigate("/Email-Confirmation");
            //   toast.error("Your confirmation code has been sent !, please check your email for the new one");
            // }
            toast.error(error.message.replaceAll('"', ""));
          });
      } else {
        const URL = Global.API_URL + "Customer/CustomerRegistration";
        fetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
          body: JSON.stringify({
            Email: email,
            Password: password,
            FirstName: firstName,
            LastName: lastName,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            return response.text().then((text) => {
              throw new Error(text);
            });
          })
          .then((result) => {
            toast.success("Customer Successfully registered");
            localStorage.setItem("Number", Util.encrypt(result.CustomerId));
            localStorage.setItem("User", Util.encrypt(email));
            navigate("/Email-Confirmation")
          }).catch((error) => {
            toast.error(error.message.replaceAll('"', ""));
          });
      }
    }
  }

  useEffect(() => {
    if (localStorage?.getItem("PMToken") === null) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [props]);

  return (
    <>
      <div className="container mt-60">
        <div className="row">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Profile</li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <div className="authenticationCard">
              <div className="row topBar">
                {login ? (
                  <>
                    <div className="col-6 col-md-6">
                      <button onClick={() => {
                        setLogin(false);
                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setPassword("");
                        setConfirmPassword("");
                        setPhonenumber("")
                      }}>Sign - Up</button>
                    </div>
                    <div className="col-6 col-md-6">
                      <button className="active">Sign - In</button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-6 col-md-6">
                      <button className="active">Sign - Up</button>
                    </div>
                    <div className="col-6 col-md-6">
                      <button onClick={() => {
                        setLogin(true)
                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setPassword("");
                        setConfirmPassword("");
                        setPhonenumber("")
                        }}>Sign - In</button>
                    </div>
                  </>
                )}
              </div>
              {login ? (
                <form  onSubmit={(e)=>e.preventDefault()}>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2"><label>Email:</label></div>
                    <div className="col-12 col-md-10">
                      <input className="form-control" placeholder="Type Here" required type="email" onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2"><label>Password:</label></div>
                    <div className="col-12 col-md-10 showPass">
                      <input className="form-control" type={show1 ? "text" : "password"} placeholder="Type Here" required onChange={(e) => setPassword(e.target.value)}></input>
                      {show1 ? <AiOutlineEye onClick={() => setShow1(false)} /> : <AiOutlineEyeInvisible onClick={() => setShow1(true)} />}
                    </div>
                  </div>
                  <div className="row mb-35 submit">
                    <div className="col-12 text-center">
                      <button onClick={(e) => handleSubmit(e)}>Sign - In</button>
                    </div>
                    <div className="col-12 text-center">
                      <Link to="/Forget-Password">Forget Password?</Link>
                    </div>
                  </div>
                </form>
              ) : (
                <form  autoComplete="new-password"  onSubmit={(e)=>e.preventDefault()}>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2">
                      <label>First Name:</label></div>
                    <div className="col-12 col-md-5 mb-md-0 mb-3">
                      <input name="myInput" type="text" autoComplete="off" id="myInput" className="form-control" placeholder="First Name" required onChange={(e) => setFirstName(e.target.value)}></input>
                    </div>
                    <div className="col-12 col-md-5">
                      <input className="form-control" placeholder="Last Name" required onChange={(e) => setLastName(e.target.value)}></input>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2"><label>Email:</label></div>
                    <div className="col-12 col-md-10">
                      <input className="form-control" placeholder="Type Here" required onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2"><label>Phone Number:</label></div>
                    <div className="col-12 col-md-10">
                      <input className="form-control" placeholder="Type Here" required onChange={(e) => setPhonenumber(e.target.value)}></input>
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2"><label>Password:</label></div>
                    <div className="col-12 col-md-10 showPass">
                      <input className="form-control" type={show1 ? "text" : "password"} placeholder="Type Here" required onChange={(e) => setPassword(e.target.value)}></input>
                      {show1 ? <AiOutlineEye onClick={() => setShow1(false)} /> : <AiOutlineEyeInvisible onClick={() => setShow1(true)} />}
                    </div>
                  </div>
                  <div className="row mb-35">
                    <div className="col-12 col-md-2"><label>Confirm Pass:</label></div>
                    <div className="col-12 col-md-10 showPass">
                      <input className="form-control" type={show1 ? "text" : "password"} placeholder="Type Here" required onChange={(e) => setConfirmPassword(e.target.value)}></input>
                      {show2 ? <AiOutlineEye onClick={() => setShow2(false)} /> : <AiOutlineEyeInvisible onClick={() => setShow2(true)} />}
                    </div>
                  </div>
                  <div className="row mb-35 submit">
                    <div className="col-12 text-center">
                      <button onClick={(e) => handleSubmit(e)}>Sign - Up</button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserAuthentication;
